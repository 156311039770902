import React, { Fragment, useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import BurgerMenu from './Menus/BurgerMenu';
import CookieConsent from 'react-cookie-consent'

const Layout = (props) => {

    

    return (
        <Fragment>
            <div className="header">
                <div className="header__logo-box">
                    <img src="https://res.cloudinary.com/fsb-land-holding/image/upload/v1620050712/project01/logo/theossuary_i6jjig.jpg" alt="Logo" 
                        className="header__logo" />
                </div>
                <ul className="header__ul">
                    <li className="header__ul--item">
                        <Link to={'/pages/columbarium'}>The Columbarium</Link>
                    </li>
                    <li className="header__ul--item">
                        <Link to={'/pages/products'}>Vaults and Products</Link>
                    </li>
                    <li className="header__ul--item">
                        <Link to={'/pages/services'}>Services</Link>
                    </li>
                    <li className="header__ul--item">
                        <Link to={'/pages/afterSales'}>After Sales</Link>
                    </li>
                    <li className="header__ul--item">
                        <Link to={'/pages/login'}>Reservation and Purchase</Link>
                    </li>
                    <li className="header__ul--item">
                        <Link to={'/pages/contact'}>Contact Us</Link>
                    </li>
                    {
                        // <li className="header__ul--item">
                        //     <Link to={'/pages/faq'}>FAQs</Link>
                        // </li>
                    }
                </ul>
                <BurgerMenu />
            </div>
            
            {props.children}
            <footer className="footer">
                <div className="container">
                    <div className="row">
                        <div className="col-md-3">
                            <h3>TOP LINKS</h3>
                            <div className="footer__content">
                                <ul className="footer__list">
                                    <li className="footer__item">
                                        <Link to={'/pages/columbarium'} className="footer__link" >The Columbarium</Link>
                                    </li>
                                    <li className="footer__item">
                                        <Link to={'/pages/products'} className="footer__link" >Vaults and Products</Link>
                                    </li>
                                    <li className="footer__item">
                                        <Link to={'/pages/services'} className="footer__link" >Services</Link>
                                    </li>
                                    <li className="footer__item">
                                        <Link to={'/pages/afterSales'} className="footer__link" >After Sales</Link>
                                    </li>
                                    <li className="footer__item">
                                        <Link to={'/pages/login'} className="footer__link" >Reservation and Purchase</Link>
                                    </li>
                                    <li className="footer__item">
                                        <Link to={'/pages/contact'} className="footer__link" >Contact Us</Link>
                                    </li>
                                    
                                    {
                                        // <li className="footer__item">
                                        //    <Link to={'/pages/faq'} className="footer__link" >FAQs</Link>
                                        // </li>
                                    }
                                    <li className="footer__item">
                                        <Link to={'/pages/terms-of-use'} className="footer__link" >Terms of Use</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-5">
                            <h3>CONTACT US</h3>
                            <div className="footer__content">
                                <ul className="footer__list">
                                    <li className="footer__contact">+63(32)5056937</li>
                                    <li className="footer__contact">+63(32)5115141</li>
                                    <li className="footer__contact">+639177010516</li>
                                    <li className="footer__contact">ossuarysales.inquiry@fsbland.com</li>
                                    <li className="footer__contact">
                                        <a className="footer__contact--icon" href="https://www.facebook.com/fsbland">FSB Facebook Page</a>
                                    </li>
                                    <li className="footer__contact">Unit 4 Plaza Margarita, Cebu South National Highway, Barangay Linao Lipata, Minglanilla Cebu 6046</li>
                                    <li className="footer__contact">The Ossuary @ Kahangan Estate DHSUD LS-R07-22-076</li>
                                    <li className="footer__contact">Developer: FSB Land Holdings</li>
                                    <li className="footer__contact">IPO Certificate No. 2022-01984-N</li>
                                    <li className="footer__contact">IPO Registration No. 4/2022/00519663</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md">
                            <h3>ABOUT US</h3>
                            <p className="footer__content">
                                Nestled in the Philippine’s newest hillside resort community of Kahangan Estate Cebu, 
                                The Ossuary is a state-of-the-art columbarium that provides a balance of tradition and technology.​
                                <br /><br />
                                Incorporated with an iconic destination Chapel and nature’s elements, 
                                visiting your dearly departed will truly be a memorable experience everytime.
                            </p>
                        </div>
                    </div>
                </div>
            </footer>
            <footer className="copyright">
                <div className="container">
                    <div className="row">
                        <div className="col-md ml">
                            <p className="copyright__left">&copy; 2022 The Ossuary - FSB Land Holdings</p>
                        </div>
                    </div>
                </div>
            </footer>
            <CookieConsent
                debug={false}
                location="bottom"
                style={{ background: "#79977C", textAlign: "left", fontSize: "16px"}}
                buttonStyle={{color: "#000", background: "#fff", fontSize: "16px" }}
                buttonText="I Got It."
                expires={1}
            >
                By using our site you agree to our use of necessary cookies to deliver a better site experience.
            </CookieConsent>
            
        </Fragment>
    );
};

export default Layout;