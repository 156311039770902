import React, { useEffect } from 'react';
import { Link } from "react-router-dom";
import CustomerLayout from '../../../components/Layouts/CustomerLayout';

const OrderConfirmation = () => {
    
    useEffect(() => {
        goTop();
    });

    const goTop = () => {
        window.scrollTo(0, 0);
    };

    return (
        <CustomerLayout>
            <main>
                <div className="container center">
                    <div className="row mb-20">
                        <div className="col-md">
                            <div className="signup-confirmation vh-70">
                                <div className="wrapper-1">
                                    <div className="wrapper-2">
                                        <h1>Order has been successfully Submitted!</h1> 
                                        <p>You should receive a email along with your order details. </p>
                                        <p>Should there be no email received, please contact  info@fsblandholding.com. </p>
                                        <br /><br />
                                        <Link className="go-home" to="/pages/secured/user/my-purchases">Click to view purchases</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </CustomerLayout>
    );
};

export default OrderConfirmation;