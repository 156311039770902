import React, { Fragment } from 'react';

const OrderPaymentOption20 = (props) => {

    return(
        (props.paymentOption.isInstallment && props.paymentOption.paymentOptionId == 4 && props.product.paymentOptionId == 4) &&
            <Fragment>
                <div className="description">
                    <table className="table table-success font-size-12 borderless">
                        <tbody>
                            <tr>
                                <td><b>Down Payment 20%</b></td>
                                <td><b>{props.formatCurrency(props.product.checkoutAmount)}</b></td>
                            </tr>
                            <tr>
                                <td>Installment Period:</td>
                                <td><b>{props.product.monthlyPaymentOption}</b></td>
                            </tr>
                            <tr>
                                <td>Monthly Amortization:</td>
                                <td><b>{props.formatCurrency(props.product.monthlyAmortization)}</b></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </Fragment>
    )
}

export default OrderPaymentOption20;