import React, { useEffect } from 'react';
import Layout from '../components/Layouts/Layout'
import UnderConstruction from '../components/UnderConstruction'

const TermsOfUse = () => {
    
    useEffect(() => {
        goTop();
    }, []);

    const goTop = () => {
        window.scrollTo(0, 0);
    };
    
    return (
        <Layout>
            <main>
                <div className="container">
                    <div className="row mt-160 mb-20">
                        <div className="col-md text-center">
                            <h1 className="heading-tertiary u-margin-bottom-small">Terms of Use</h1>
                        </div>
                    </div>
                    <div className="row mb-40">
                        <div className="col-md">
                            <p className="paragraph mt-10">
                                Welcome to The Ossuary website. If you continue to browse and use this website, 
                                you agree to comply with and be bound by the following terms and conditions of use, 
                                which together with our privacy policy govern FSB Land Holding’s relationship with 
                                you in relation to this website. If you disagree with any part of these terms and conditions, 
                                please do not use our website
                            </p>
                            <p className="paragraph mt-10">
                                The use of this website is subject to the following terms of use:
                            </p>
                            <ul className="paragraph mt-10 ul-type-disc">
                                <li>The content of the pages of this website is for your general information and use only. It is subject to change without notice. Accordingly, readers and users of this website are encouraged to constantly verify factual and subsequent changes before acting upon any information contained herein.</li>
                                <li>While information contained herein is based on present plans which have been prepared with utmost care and is given in good faith, these may or may not reflect the most current developments.</li>
                                <li>This website contains material which is owned by or licensed to us. This material includes, but is not limited to prose, designs, pictures, logos or any written and visual material.  Reproduction is prohibited and is protected by copyright, trademark and other intellectual property laws.</li>
                                <li>Your use of any information or materials on this website is entirely at your own risk, for which we shall not be liable. It shall be your own responsibility to ensure that any products, services or information available through this website meet your specific requirements.</li>
                                <li>FSB Land Holdings Inc., at its sole discretion, may choose to change the terms, conditions and operation of this website at any time.</li>
                                <li>This website may use cookies to monitor browsing preferences and collect personal data subject to the basic guidelines set by the Philippine Data Privacy Act of 2012.</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </main>
        </Layout>
    );
};


export default TermsOfUse;