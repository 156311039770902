import React, { Fragment, useState, useEffect } from 'react';
import CustomerLayout from '../../../components/Layouts/CustomerLayout';
import { Navigate, useParams } from "react-router-dom";
import { getOrderDetailContinue, updateOrderPaymentOption } from '../../../apiCall/cart'
import { removeVaultCart } from '../../../apiCall/vault'
import Spinner from '../../../components/Spinner'
import PaymentOption50 from '../../../components/PaymentOption50';
import PaymentOption30 from '../../../components/PaymentOption30';
import PaymentOption20 from '../../../components/PaymentOption20';

const CartContinue = () => {

    const [showSpinner, setShowSpinner] = useState(false);
    const [error, setErrors] = useState("");
    const [value, setValues] = useState([]);
    const [totalContractAmount, setTotalContractAmount] = useState('0.00');
    const [totalVATAmount, setTotalVATAmount] = useState('0.00');
    const [totalContractWithVATAmount, setTotalContractWithVATAmount] = useState('0.00');
    const [totalVaultNetAmount, setTotalVaultNetAmount] = useState('0.00');
    const [totalCheckoutAmount, setTotalCheckoutAmount] = useState('0.00');
    const [totalDiscountAmount, setTotalDiscountAmount] = useState('0.00');
    const [totalReservedAmount, setTotalReservedAmount] = useState('0.00');
    const [totalPurchasedAmount, setTotalPurchasedAmount] = useState('0.00');
    const [summaryId, setSummaryId] = useState('');
    const [paymentOptions, setPaymentOptions] = useState([]);
    const [showProceed, setShowProceed] = useState(false);
    
    const params = useParams();

    useEffect(() => {
        init();
        goTop();
    }, []);

    const goTop = () => {
        window.scrollTo(0, 0);
    };

    const init = () => {
        
        const summaryId = params.summaryId;
        
        getOrderDetail(summaryId);
        setSummaryId(summaryId);
    }

    const getOrderDetail = (summaryId) => {
        setShowSpinner(true);
        getOrderDetailContinue(summaryId).then(res => {
            if (res.isSuccess === true) {
                
                setShowSpinner(false);
                setErrors("");
                setValues(res.dataDictionary.data);
                setTotalContractAmount(res.dataDictionary.totalContractAmount);
                setTotalVATAmount(res.dataDictionary.totalVATAmount);
                setTotalContractWithVATAmount(res.dataDictionary.totalContractWithVATAmount);
                setTotalVaultNetAmount(res.dataDictionary.totalVaultNetAmount);
                setTotalCheckoutAmount(res.dataDictionary.totalCheckoutAmount);
                setTotalDiscountAmount(res.dataDictionary.totalDiscountAmount);
                setTotalReservedAmount(res.dataDictionary.totalReservedAmount);
                setTotalPurchasedAmount(res.dataDictionary.totalPurchasedAmount);
                setPaymentOptions(res.dataDictionary.paymentOptions);
            } 
            else {
                setShowSpinner(false);
                setErrors(res.message);
            }
        })
    };

    const [proceed, setProceed] = useState(false);

    const formatCurrency = (value) => {
        return Number(value).toLocaleString("en-US", {
            style: "currency",
            currency: "PHP"
            
        });
    }

    const onClickModify = () => {
        
        removeVaultCart(summaryId).then(res => {
            if (res.isSuccess === true) {
                setShowSpinner(false);
                setProceed(false);
            } 
            else {
                setShowSpinner(false);
                setErrors(res.message);
            }
        })
    }

    const onClickProceed = () => {
        
        setShowSpinner(false);
        setErrors("");
        setProceed(true);

    };

    const onClickReCompute = (orderDetailId, paymentOptionId, orderSummaryId, noOfMonth) => {
        setShowSpinner(true);
        setShowProceed(true);
        if(noOfMonth == 'undefined' || noOfMonth == null || noOfMonth == 0)
        {
            if(paymentOptionId == 1) {
                noOfMonth = 1;
            } else if (paymentOptionId == 2) {
                noOfMonth = 3;
            } else if (paymentOptionId == 3) {
                noOfMonth = 3;
            } else if (paymentOptionId == 4) {
                noOfMonth = 3;
            } else {
                noOfMonth = 1;
            }

        }

        var request = {
            OrderDetailId: orderDetailId, 
            PaymentOptionId: paymentOptionId, 
            OrderSummaryId: orderSummaryId,
            NoMonthAmort: noOfMonth
        };

        updateOrderPaymentOption(request).then(res => {
            if (res.isSuccess === true) {
                
                getOrderDetailContinue(summaryId).then(res => {
                    if (res.isSuccess === true) {
                        
                        setShowSpinner(false);
                        setErrors("");
                        setValues(res.dataDictionary.data);
                        setTotalContractAmount(res.dataDictionary.totalContractAmount);
                        setTotalVATAmount(res.dataDictionary.totalVATAmount);
                        setTotalContractWithVATAmount(res.dataDictionary.totalContractWithVATAmount);
                        setTotalVaultNetAmount(res.dataDictionary.totalVaultNetAmount);
                        setTotalCheckoutAmount(res.dataDictionary.totalCheckoutAmount);
                        setTotalDiscountAmount(res.dataDictionary.totalDiscountAmount);
                        setTotalReservedAmount(res.dataDictionary.totalReservedAmount);
                        setTotalPurchasedAmount(res.dataDictionary.totalPurchasedAmount);
                        setPaymentOptions(res.dataDictionary.paymentOptions);
                    } 
                    else {
                        setShowSpinner(false);
                        setErrors(res.message);
                    }
                })
            } 
            else {
                setShowSpinner(false);
                setErrors(res.message);
            }
        })
    }

    const handleRedirect = () => {
        
        if (proceed) {
            var link = `/pages/secured/user/order-review-continue/${summaryId}`
            return <Navigate to={link} />;
        }
    }

    return (
        <CustomerLayout>
            { showSpinner && <Spinner /> }
            <main>
                <section className="reservation-section">
                    <div className="row mb-12">
                        <div className="col-md">
                            <h3 className="err-msg">{ error }</h3>
                        </div>
                    </div>
                    <div className="row mb-12">
                        <div className="col-md">
                            <ul className="products">
                                {
                                    value.map((product, idx) =>
                                        <div className="row row-cart" key={idx}>
                                            <div className="col-md-3 thumbnail">
                                                <img src={product.image} alt={product.name} />
                                            </div>
                                            <div className="col-md-9 col-cart">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="name"><h1>{product.name}</h1></div>
                                                    </div>
                                                </div>
                                                <div className="row col-cart">
                                                    <div className="col-md-4 ">
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <div className="description font-size-14">Vault Price:</div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="description right font-size-14 small-view">
                                                                    <b>{formatCurrency(product.vaultSellingPrice)}</b>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <div className="description">{product.building}</div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <div className="description">{product.floor} {product.pillarName} {product.level}{product.column}</div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <div className="description">{product.vaultCode}</div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <div className="description">VAT Inclusive</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-8 mt-2">
                                                        {
                                                            paymentOptions.map((paymentOption, id2) =>
                                                                <Fragment  key={id2}>
                                                                    <div className="row mt-4">
                                                                        <div className="col-md-7 mt-3">
                                                                            <h4>{paymentOption.paymentOptionDisplay}</h4>
                                                                            {paymentOption.paymentOptionId === 5 && <h4>Expired at {product.registrationExpiration}</h4>} 
                                                                        </div>
                                                                        <div className="col-md-1 right small-view">
                                                                                <input type="radio" 
                                                                                    className="rdoButton checkbox-inline" 
                                                                                    id={paymentOption.paymentOptionId} 
                                                                                    name={product.vaultId}
                                                                                    value={paymentOption.paymentOptionId} 
                                                                                    onChange={() => onClickReCompute(product.orderDetailId, paymentOption.paymentOptionId, product.orderSummaryId)}
                                                                                    checked={product.paymentOptionId === paymentOption.paymentOptionId}
                                                                                    disabled={(product.isEnabled === '0' || paymentOption.paymentOptionId === 5) && ''}
                                                                                /> 
                                                                        </div>
                                                                        <div className="col-md-3 mt-3">
                                                                            { (product.paymentOptionId === paymentOption.paymentOptionId && 
                                                                                paymentOption.paymentOptionId !== 5) && <h4>{formatCurrency(product.checkoutAmount)}</h4> } 
                                                                        </div>
                                                                    </div>

                                                                    <PaymentOption50 
                                                                        onClickReCompute={onClickReCompute} 
                                                                        formatCurrency={formatCurrency}
                                                                        product={product}
                                                                        paymentOption={paymentOption}
                                                                        length={value.length}
                                                                    />
                                                                    <PaymentOption30
                                                                        onClickReCompute={onClickReCompute} 
                                                                        formatCurrency={formatCurrency}
                                                                        product={product}
                                                                        paymentOption={paymentOption}
                                                                    />
                                                                    <PaymentOption20
                                                                        onClickReCompute={onClickReCompute} 
                                                                        formatCurrency={formatCurrency}
                                                                        product={product}
                                                                        paymentOption={paymentOption}
                                                                    />
                                                                </Fragment>
                                                            )
                                                        }
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </div>
                                    )
                                }
                            </ul>
                        </div>
                    </div>
                    <div className="row mb-12">
                        <div className="col-md-3">&nbsp;</div>
                        <div className="col-md-9 mb-5">
                            <table className="summary-price">
                                <tbody>
                                    <tr>
                                        <td className="font-size-18 summary-price-label"><b>Amount Due for Checkout:</b></td>
                                        <td className="font-size-18 right">
                                            {formatCurrency(totalCheckoutAmount)}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    { (totalCheckoutAmount > 0) && <div className="row mb-12 summary"> 
                        <div className="col-md right">
                            <button onClick={onClickProceed} className="btn btn--primary  mr-4">Proceed</button>
                        </div>
                    </div> }
                </section>
            </main>
            { proceed && handleRedirect() }
        </CustomerLayout>
    );
};

export default CartContinue;