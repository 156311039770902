import React, { Fragment, useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import BurgerMenu from "./Menus/BurgerMenu"
import BreadcrumbsMenu from './Menus/BreadcrumbsMenu';
import DropdownMenu from './Menus/DropdownMenu';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const CustomerLayout = (props) => {

    const [showTimeoutModal, setShowTimeoutModal] = useState(false);
    const handleCloseTimeoutModal = () => setCloseTimeoutModal(false);
    
    let timeWarning;
    let timeLogout;

    function handleTimeoutLogout() {
        
        localStorage.removeItem("fsb");
        window.location = '/pages/login';
    }

    function setCloseTimeoutModal() {
        setShowTimeoutModal(false);
    }

    function handleTimeoutWarning() {
        setShowTimeoutModal(true);
    }

    function handleTimeoutSubmit() {
        setShowTimeoutModal(false);
        resetTimer();
    }

    function startTimers() {
        timeWarning = setTimeout(handleTimeoutWarning, 1500000) // 25 mins
        timeLogout = setTimeout(handleTimeoutLogout, 1800000) // 30 mins
    };

    function resetTimer() {
        clearTimeout(timeWarning);
        clearTimeout(timeLogout);

        startTimers();
        setShowTimeoutModal(false);
    };

    useEffect(() => {
        document.addEventListener("mousemove", resetTimer);
        document.addEventListener("keydown", resetTimer);
        document.addEventListener("keypress", resetTimer);
    }, []);

    return (
        <Fragment>
            <div className="header">
                <div className="header__logo-box">
                    <img src="https://res.cloudinary.com/fsb-land-holding/image/upload/v1620050712/project01/logo/theossuary_i6jjig.jpg" alt="Logo"
                        className="header__logo" />
                </div>
                <ul className="header__ul">
                    <li className="header__ul--item">
                        <Link to={'/pages/columbarium'}>The Columbarium</Link>
                    </li>
                    <li className="header__ul--item">
                        <Link to={'/pages/products'}>Vaults and Products</Link>
                    </li>
                    <li className="header__ul--item">
                        <Link to={'/pages/services'}>Services</Link>
                    </li>
                    <li className="header__ul--item">
                        <Link to={'/pages/afterSales'}>After Sales</Link>
                    </li>
                    <li className="header__ul--item">
                        <Link to={'/pages/login'}>Reservation and Purchase</Link>
                    </li>
                    <li className="header__ul--item">
                        <Link to={'/pages/contact'}>Contact Us</Link>
                    </li>
                    {
                        // <li className="header__ul--item">
                        //    <Link to={'/pages/faq'}>FAQs</Link>
                        // </li>
                    }
                </ul>
                <BurgerMenu />
            </div>
            <div className="container">
                <div className="row mt-160 account-header">
                    <div className="col-md-6">
                        <BreadcrumbsMenu />
                    </div>
                    <div className="col-md-6">
                        <DropdownMenu />
                    </div>
                </div>
                <div className="row mt-20 mb-40">
                    <div className="col-md-12">
                        <Modal show={showTimeoutModal} onHide={handleCloseTimeoutModal} centered>
                            <Modal.Header closeButton>
                                <Modal.Title className="font-size-16">Ossuary - Confirmation</Modal.Title>
                            </Modal.Header>
                            <Modal.Body  className="font-size-16">
                                Your session is about to expire due to inactivity. 
                                To continue using our website, please click the "Continue" button. 
                                If you do not take any action, your session will be automatically terminated.
                            </Modal.Body>
                            <Modal.Footer>
                                <Button className="btn bg-color-primary" variant="secondary" onClick={() => { handleTimeoutSubmit() }}>
                                    CONTINUE
                                </Button>
                            </Modal.Footer>
                        </Modal>
                        {props.children}
                    </div>
                </div>
            </div>
            <footer className="footer">
                <div className="container">
                    <div className="row">
                        <div className="col-md-3">
                            <h3>TOP LINKS</h3>
                            <div className="footer__content">
                                <ul className="footer__list">
                                    <li className="footer__item">
                                        <Link to={'/pages/columbarium'} className="footer__link" >The Columbarium</Link>
                                    </li>
                                    <li className="footer__item">
                                        <Link to={'/pages/products'} className="footer__link" >Vaults and Products</Link>
                                    </li>
                                    <li className="footer__item">
                                        <Link to={'/pages/services'} className="footer__link" >Services</Link>
                                    </li>
                                    <li className="footer__item">
                                        <Link to={'/pages/afterSales'} className="footer__link" >After Sales</Link>
                                    </li>
                                    <li className="footer__item">
                                        <Link to={'/pages/login'} className="footer__link" >Reservation and Purchase</Link>
                                    </li>
                                    <li className="footer__item">
                                        <Link to={'/pages/contact'} className="footer__link" >Contact Us</Link>
                                    </li>
                                    {
                                        // <li className="footer__item">
                                        //    <Link to={'/pages/faq'} className="footer__link" >FAQs</Link>
                                        // </li>
                                    }
                                    <li className="footer__item">
                                        <Link to={'/pages/terms-of-use'} className="footer__link" >Terms of Use</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-5">
                            <h3>CONTACT US</h3>
                            <div className="footer__content">
                                <ul className="footer__list">
                                    <li className="footer__contact">+63(32)5056937</li>
                                    <li className="footer__contact">+63(32)5115141</li>
                                    <li className="footer__contact">+639177010516</li>
                                    <li className="footer__contact">ossuarysales.inquiry@fsbland.com</li>
                                    <li className="footer__contact">
                                        <a className="footer__contact--icon" href="https://www.facebook.com/fsbland">FSB Facebook Page</a>
                                    </li>
                                    <li className="footer__contact">Unit 4 Plaza Margarita, Cebu South National Highway, Barangay Linao Lipata, Minglanilla Cebu 6046</li>
                                    <li className="footer__contact">The Ossuary @ Kahangan Estate DHSUD LS-R07-22-076</li>
                                    <li className="footer__contact">Developer: FSB Land Holdings</li>
                                    <li className="footer__contact">IPO Certificate No. 2022-01984-N</li>
                                    <li className="footer__contact">IPO Registration No. 4/2022/00519663</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md">
                            <h3>ABOUT US</h3>
                            <p className="footer__content">
                                Nestled in the Philippine’s newest hillside resort community of Kahangan Estate Cebu, 
                                The Ossuary is a state-of-the-art columbarium that provides a balance of tradition and technology.​
                                <br /><br />
                                Incorporated with an iconic destination Chapel and nature’s elements, 
                                visiting your dearly departed will truly be a memorable experience everytime.
                            </p>
                        </div>
                    </div>
                </div>
            </footer>
            <footer className="copyright">
                <div className="container">
                    <div className="row">
                        <div className="col-md ml">
                            <p className="copyright__left">&copy; 2021 The Ossuary - FSB Land Holdings</p>
                        </div>
                    </div>
                </div>
            </footer>
        </Fragment>
    );
};

export default CustomerLayout;