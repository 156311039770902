

export const checkoutPage = (merchantid, totalPrice, productName, orderid, checkoutSessionId, apiversion, firstName, lastName, email, contactNumber) => {
    
    declare var Checkout: any;

    const script = document.createElement('script');
    script.src = "https://ap-gateway.mastercard.com/checkout/version/62/checkout.js";
    // script.src = "https://ap-gateway.mastercard.com/static/checkout/checkout.min.js";
    script.async = true;
    document.body.appendChild(script);
    
    //Checkout.configure({
    //    session: { 
    //      id: checkoutSessionId
    //         },
    //    interaction: {
    //          merchant: {
    //              name: 'FSB LAND HOLDINGS - THE OSSUARY',
    //              address: {
    //                  line1: 'Pres. Sergio Osmena Sr. Highway',
    //                  line2: '1225 Zobel Roxas St, Malate, Manila'            
    //              }    
    //          },
    //          displayControl: {
    //            billingAddress  : 'HIDE',
    //            customerEmail   : 'HIDE',
    //            shipping        : 'HIDE'
    //        }
    //     }
    //  });
    
    // Checkout.showPaymentPage();

    Checkout.configure({
        session: { 
            id: checkoutSessionId
        },
        interaction: {
            merchant: {
                name: 'FSB LAND HOLDINGS - THE OSSUARY',
                address: {
                    line1: 'Pres. Sergio Osmena Sr. Highway',
                    line2: '1225 Zobel Roxas St, Malate, Manila'            
                }    
            },
            displayControl: {
                billingAddress  : 'HIDE',
                customerEmail   : 'HIDE',
                shipping        : 'HIDE'
            }
        },
        customer: {
            firstName: firstName, // Cardholder First Name
            lastName: lastName, // Cardholder Last Name
            email: email,  // Cardholder Email Address
            phone: contactNumber // Cardholder Phone Number
        },
        order: {
            reference: orderid // Order reference only
        }
    });
    
    Checkout.showPaymentPage();
}