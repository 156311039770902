import React, { useState, useEffect } from 'react';
import { useNavigate, Navigate } from "react-router-dom";
import BootstrapTable from 'react-bootstrap-table-next';
import PaginatorFactory from 'react-bootstrap-table2-paginator'
import DatePicker from 'react-datepicker';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import CustomerLayout from '../../../components/Layouts/CustomerLayout';
import Spinner from '../../../components/Spinner';
import { getAllPurchaseList } from '../../../apiCall/cart';
import { exportAllPurchaseList } from '../../../apiCall/exports' 

const PurchaseList = () => {

    const [showSpinner, setShowSpinner] = useState(false);
    const [viewDetail, setViewDetail] = useState(false);
    const [data, setData] = useState([]);
    const [values, setValues] = useState({
        summaryid: '',
        referenceNumber: ''
    })
    const [showViewDetail, setShowViewDetail] = useState(false);

    const [errors, setErrors] = useState({
        messages: ''
    });

    const [modalMessage, setModalMessage] = useState('');
    const [showConfirmation, setShowConfirmation] = useState(false);
    const handleCloseConfirmation = () => setShowConfirmation(false);

    const [dtFrom, setDtFrom] = useState(new Date());
    const [dtTo, setDtTo] = useState(new Date());
    const [referenceNumber, setReferenceNumber] = useState('');
    const [vaultCode, setVaultCode] = useState('');

    const navigate = useNavigate();

    useEffect(() => {
        getPurchaseList();
        goTop();
    }, []);

    const goTop = () => {
        window.scrollTo(0, 0);
    };

    const getPurchaseList = () => {
        
        const request = {
            DtFrom: dtFrom, 
            DtTo: dtTo,
            ReferenceNumber: referenceNumber,
            VaultCode: vaultCode
        }

        setShowSpinner(true);

        getAllPurchaseList(request).then(res => {
            if (res.isSuccess === true) {
                setShowSpinner(false);
                setErrors("");
                setData(res.dataDictionary.data);
            } 
            else {
                if(res.status === 401) {
                    
                    localStorage.removeItem("fsb");
                    navigate('/pages/login');
                }

                setShowSpinner(false);
                setErrors(res.message);
            }
        })
    };

    const exportPurchaseList = () => {
        
        const request = {
            DtFrom: dtFrom, 
            DtTo: dtTo,
            ReferenceNumber: referenceNumber,
            VaultCode: vaultCode
        }

        setShowSpinner(true);

        exportAllPurchaseList(request).then(res => {

            try{
                console.log(res.dataDictionary)
                var filename = res.dataDictionary.filename;
                var link = document.createElement('a');
                link.download = filename;
                link.href = "data:application/octet-stream;base64," + res.dataDictionary.export;
                link.target = '_blank';
                document.body.appendChild(link); // Needed for Firefox
                link.click();
                document.body.removeChild(link);
                setShowSpinner(false);
                setErrors("");
                setData(res.dataDictionary.data);
                
            }
            catch(err)
            {
                setShowSpinner(false);
                setErrors(err.message);
            }
        })
    };

    const columns = [
        { dataField: "referenceNumber", text: "Reference Number", sort: true, headerTitle: true },
        { dataField: "date", text: "Date", sort: true, headerTitle: true },
        { dataField: "firstname", text: "First Name", sort: true, headerTitle: true },
        { dataField: "lastname", text: "Last Name", sort: true, headerTitle: true },
        { dataField: "orderStatus", text: "Order Status", sort: true, headerTitle: true },
        { dataField: "qty", text: "Qty", sort: true, headerTitle: true },
        // { dataField: "vaultcode", text: "Vault Code", sort: true, headerTitle: true, style: { width: 400 } },
        { dataField: "totalPrice", text: "Total Net Amount", sort: true, headerTitle: true },
        
    ];

    const selectRow = {
        mode: 'radio',
        clickToSelect: true,
        bgColor: '#A6B8A1',
        hideSelectColumn: true,
        onSelect: (row, isSelect, rowIndex, e) => {
            if(isSelect) {
                setValues({
                    summaryid: row.summaryid, 
                    referenceNumber: row.referenceNumber
                })
            } 
        }
    };

    const renderShowsTotal = (start, to, total) => {
        return (
          <p style={ { color: 'blue' } }>
            Showing Records From { start } to { to } of { total }
          </p>
        );
    }

    const options = {
       defaultSortName: 'date',  // default sort column name
       defaultSortOrder: 'desc',  // default sort order
        page: 1,  // which page you want to show as default
        sizePerPageList: [ {
          text: '5', value: 5
        }, {
            text: '15', value: 15
        }, {
          text: '25', value: 25
        }, {
          text: 'All', value: (data.data) ? data.data.length : 9999999999
        } ], 
        sizePerPage: 10,  // which size per page you want to locate as default
        pageStartIndex: 1, // where to start counting the pages
        paginationSize: 3,  // the pagination bar size.
        prePage: 'Prev', // Previous page button text
        nextPage: 'Next', // Next page button text
        firstPage: 'First', // First page button text
        lastPage: 'Last', // Last page button text
        paginationShowsTotal: renderShowsTotal,  // Accept bool or function
        paginationPosition: 'bottom',  // default is bottom, top and both is all available
        hideSizePerPage: true // You can hide the dropdown for sizePerPage
        // alwaysShowAllBtns: true // Always show next and previous button
        // withFirstAndLast: false > Hide the going to First and Last page button
    };

    const handleViewDetail = () => {
        const { summaryid, referenceNumber } = values;
        var path = `/pages/secured/user/admin-purchase-details/purchase/${summaryid}`
        return <Navigate push to={path} />; 
    }

    const handleDtFromchange = (date) => {
        setDtFrom(date);
    }

    const handleDtTochange = (date) => {
        setDtTo(date);
    }

    const handleSearchClick = () => {
        setValues({
            summaryid: '',
            referenceNumber: ''
        })
        setData([]);
        setShowViewDetail(false);
        getPurchaseList();
    }

    const handleExportClick = () => {
        setShowViewDetail(false);
        exportPurchaseList();
    }

    const handleChange = name => event => {
        
        if(name === 'referenceNumber') {
            setReferenceNumber(event.target.value);
        } else if (name === 'vaultCode') {
            setVaultCode(event.target.value);
        }
    };

    const rowEvents = {
        onDoubleClick: (e, row, rowIndex) => {
            if(row.summaryid != null && row.summaryid != 'undefined') 
            {
                setValues({
                    summaryid: row.summaryid, 
                    referenceNumber: row.referenceNumber
                })
    
                setShowConfirmation(true);
                setModalMessage(`You have selected: ${row.referenceNumber}. Do you want to proceed?`);
            }
        }
    };

    const handleCancel = () => {
        setShowConfirmation(false);
        setShowSpinner(false);
    };

    const handleSubmit = () => {
        setShowConfirmation(false);
        setModalMessage('');
        setViewDetail(true);
    };

    return (
        <CustomerLayout>
            <main>
                <section className="reservation-section">
                    <div className="row mb-12">
                        <h2 className="ml-4">Purchases and Maintenance</h2>
                    </div>
                    <div className="row mb-12">
                        <div className="col-md">
                            { showSpinner && <Spinner /> }
                            <Modal show={showConfirmation} onHide={handleCloseConfirmation} centered>
                                <Modal.Header closeButton>
                                    <Modal.Title className="font-size-16">Ossuary - Confirmation</Modal.Title>
                                </Modal.Header>
                                <Modal.Body  className="font-size-16">{modalMessage}</Modal.Body>
                                <Modal.Footer>
                                    <Button className="btn bg-color-primary" variant="secondary" onClick={() => { handleSubmit() }}>
                                        OK
                                    </Button>
                                    <Button className="btn bg-color-default" variant="secondary" onClick={() => { handleCancel(); }}>
                                        CANCEL
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                            <div className="row mb-12">
                                <div className="col-md">
                                    <h3 id="warning-message" className="err-msg">{ errors.message }</h3>
                                </div>
                            </div>
                        </div>
                    </div>   
                    <div className="row mb-24">
                        <div className="col-md-4">
                            <label className="font-size-14 mr-5"><b>DATE FROM:</b></label>
                            <DatePicker
                                className="form-control font-size-14 height-36"
                                selected={dtFrom}
                                onChange={(date) => handleDtFromchange(date) }
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                            />
                        </div>
                        <div className="col-md-4">
                            <label className="font-size-14 mr-5"><b>DATE TO:</b></label>
                            <DatePicker
                                className="form-control font-size-14 height-36"
                                selected={dtTo}
                                onChange={(date) => handleDtTochange(date) }
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                            />
                        </div>
                    </div>
                    <div className="row mb-40">
                        <div className="col-md-4">
                            <label className="font-size-14"><b>VAULT CODE:</b></label>
                            <input type="text" className="form-control font-size-14 height-36" onChange={handleChange('vaultCode')} value={ vaultCode } />
                        </div>
                        <div className="col-md-4">
                            <label className="font-size-14"><b>REFERENCE NO:</b></label>
                            <input type="text" className="form-control font-size-14 height-36" onChange={handleChange('referenceNumber')} value={ referenceNumber } />
                        </div>
                    </div>
                    <div className="row mb-40">
                        <div className="col-md right pt-5">
                            <button onClick={handleSearchClick} className="btn btn--search mr-4">
                                <i className="fa fa-search mr-4"></i>
                                Search
                            </button>
                            <button onClick={handleExportClick} className="btn btn--search mt-2">
                                <i className="fa fa-file mr-4"></i>
                                Export
                            </button>
                        </div>
                    </div>
                    
                    <div className="row mb-12">
                        <div className="col-md scroll-card-div">
                            <BootstrapTable 
                                keyField='summaryid' 
                                data={data} 
                                columns={columns} 
                                striped
                                hover
                                condensed
                                pagination={ PaginatorFactory(options) }
                                selectRow={ selectRow }
                                id="tbl"
                                noDataIndication={ 'No results found' }
                                rowEvents={ rowEvents }
                            /> 
                        </div>
                    </div>    
                </section>
            </main>
            { viewDetail && handleViewDetail() }
        </CustomerLayout>
    );
};

export default PurchaseList;