import React, { useState } from 'react';
import { Link, Navigate } from "react-router-dom";

import Layout from '../components/Layouts/Layout';
import Spinner from "../components/Spinner";

import { signup } from '../apiCall/auth';

const SignUp = () => {

    const [values, setValues] = useState({
        firstName: '',
        middleName: '',
        lastName: '',
        email: '',
        contactNumber: '',
        success: false, 
        error: false
    });

    const [errors, setErrors] = useState({
        firstName: '',
        middleName: '',
        lastName: '',
        email: '',
        contactNumber: '',
        messages: ''
    });

    const [showSpinner, setShowSpinner] = useState(false);
    const clickSubmit = event => {
        event.preventDefault();

        setShowSpinner(true);

        const { firstName, middleName, lastName, email, contactNumber } = values;

        var request = {
            FirstName: firstName,
            MiddleName: middleName,
            LastName: lastName,
            EmailAddress: email, 
            ContactNumber: contactNumber
        };
        
        setErrors({...errors, firstName: '', middleName: '', lastName: '', email: '', contactNumber: '', messages: '' });
        setValues({...values, error: true });

        signup(request).then(res => {
            console.log(res)
            if (res.isSuccess === true) {
                setShowSpinner(false);
                setValues({...values, error: false, success: true });
            } 
            else {
                setShowSpinner(false);
                
                if(res.dataDictionary !== "undefined" && res.dataDictionary !== null) {
                    setErrors({
                        firstName: res.dataDictionary.firstName &&  res.dataDictionary.firstName,
                        middleName: res.dataDictionary.middleName &&  res.dataDictionary.middleName,
                        lastName: res.dataDictionary.lastName &&  res.dataDictionary.lastName,
                        email: res.dataDictionary.email &&  res.dataDictionary.email,
                        contactNumber: res.dataDictionary.contactNumber &&  res.dataDictionary.contactNumber
                    });
                } else {
                    setErrors({
                        messages: res.message
                    });
                }
            }
        })
    }

    const handleChange = name => event => {
        setValues({ ...values, error: false, [name]: event.target.value });
        if(name === 'firstName') {
            setErrors({...errors, firstName: '', message: '' });
        } 

        if(name === 'middleName') {
            setErrors({...errors, middleName: '', message: '' });
        }


        if(name === 'lastName') {
            setErrors({...errors, lastName: '', message: '' });
        }

        if(name === 'email') {
            setErrors({...errors, email: '', message: '' });
        }

        if(name === 'contactNumber') {
            setErrors({...errors, contactNumber: '', message: '' });
        }
    };

    const redirectUser = () => {
        if (values.success) {
            return <Navigate to="/pages/secured/signup_confirm" />;
        }
    };

    return (
        <Layout>
            <main>
                <div className="container">
                    <div className="row mt-160">
                        <div className="col-md text-center">
                            <h1 className="heading-tertiary u-margin-bottom-small">Customer Signup</h1>
                        </div>
                    </div>
                    <div className="row mb-40">
                        <div className="col-md">
                            <form>
                                <div id="loginform">
                                { showSpinner && <Spinner /> }
                                    <div className="row mb-40">
                                        <h3 id="warning-message">{ errors.messages }</h3>
                                    </div>
                                    <div className="row mb-40">
                                        <label>First Name</label>
                                        <input type="text" className="form-control" placeholder="Enter your first name" onChange={handleChange('firstName')} value={ values.firstName } />
                                        <div className="err-msg" style={{ display: errors.firstName ? '' : 'none' }}>
                                            <span>{errors.firstName}</span>
                                        </div>
                                    </div>
                                    <div className="row mb-40">
                                        <label>Middle Name</label>
                                        <input type="text" className="form-control" placeholder="Enter your middle name" onChange={handleChange('middleName')} value={ values.middleName } />
                                        <div className="err-msg" style={{ display: errors.middleName ? '' : 'none' }}>
                                            <span>{errors.middleName}</span>
                                        </div>
                                    </div>
                                    <div className="row mb-40">
                                        <label>Last Name</label>
                                        <input type="text" className="form-control" placeholder="Enter your last name" onChange={handleChange('lastName')} value={ values.lastName } />
                                        <div className="err-msg" style={{ display: errors.lastName ? '' : 'none' }}>
                                            <span>{errors.lastName}</span>
                                        </div>
                                    </div>
                                    <div className="row mb-40">
                                        <label>Email Address</label>
                                        <input type="email" className="form-control" placeholder="Enter your email address" onChange={handleChange('email')} value={ values.email } />
                                        <div className="err-msg" style={{ display: errors.email ? '' : 'none' }}>
                                            <span>{errors.email}</span>
                                        </div>
                                    </div>
                                    <div className="row mb-40">
                                        <label>Contact Number</label>
                                        <input type="text" className="form-control" placeholder="Enter your contact number" onChange={handleChange('contactNumber')} value={ values.contactNumer } />
                                        <div className="err-msg" style={{ display: errors.contactNumber ? '' : 'none' }}>
                                            <span>{errors.contactNumber}</span>
                                        </div>
                                    </div>
                                    <div id="button" className="row mb-40">
                                        <button onClick={clickSubmit}>
                                            Signup
                                        </button>
                                    </div>
                                    <div id="signup-link">
                                        <label>Already a member? Login <Link to="/pages/login">here</Link>.</label>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                
                
                
                
                <section>
                    <div className="row">
                        
                    </div>
                </section>
            </main>
            { redirectUser() }
        </Layout>
    );
};

export default SignUp;