import React from 'react';
import { Link } from "react-router-dom";

const BuildingSelection = ({data}) => {

    return (
        <div className="row">
            {   
                data && data.map((item, idx) => 
                    <div className="col-md" key={idx}>
                        <div className="product">
                            {(item.buildingCode === 'TO1') && 
                                <Link to={`/pages/secured/user/floor/${item.buildingCode}`}>
                                    <img className="product__img" alt="Product" src={item.imageUrl} />
                                    <div className="product__caption"><h1>{item.buildingName}</h1></div>
                                    {(item.buildingCode === 'TO1') && <p>Floor(s): {item.floorCount}</p>}
                                </Link> 
                            }
                            {(item.buildingCode === 'TO2') && 
                                <>
                                    <img className="product__img" alt="Product" src={item.imageUrl} />
                                    <div className="product__caption"><h1>{item.buildingName}</h1></div>
                                </>
                            }
                        </div>
                    </div>
                )
            }
        </div>
        
    )
}

export default BuildingSelection;