import React from 'react';
import { Link } from "react-router-dom";

import CustomerLayout from "../../../components/Layouts/CustomerLayout";

const AdminDashboard = () => {
    return (
        <CustomerLayout>
            <div className="row mb-12 mt-24">
                <div className="col-md-5 mt-5">
                    <div className="card p-22">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-2">
                                    <i className="fa fa-shopping-cart font-size-40 right"></i>
                                </div>
                                <div className="col-md mt-10">
                                    <Link to={`/pages/secured/user/admin-reservation`} className="btn btn--primary">Reserve Vault</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md mt-5">
                    <div className="card p-22">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-2">
                                    <i className="fa fa-shopping-bag font-size-40 right"></i>
                                </div>
                                <div className="col-md mt-10">
                                    <Link to={`/pages/secured/user/admin-purchase-list`} className="btn btn--primary">Purchases and Maintenance</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row mb-12 mt-24">
                <div className="col-md-5">
                    <div className="card p-22">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-2">
                                    <i className="fa fa-calendar font-size-40 right"></i>
                                </div>
                                <div className="col-md mt-10">
                                    <Link to={`/pages/secured/user/admin-vault-list`} className="btn btn--primary">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Vaults&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md">&nbsp;</div>
            </div>
        </CustomerLayout>
    )
};
export default AdminDashboard;