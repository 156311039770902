
import React from 'react';
import { Navigate } from "react-router-dom";

import { isAuthenticated } from './apiCall/auth';

export const  loggedIn = () => {
    if (isAuthenticated()) {
        if(isAuthenticated().role === 'admin') {
            return <Navigate to="/pages/secured/admin/dashboard" />;
        } else {
            return <Navigate to="/pages/secured/user/reservation" />;
        }
    } else {
        return <Navigate to="/pages/login" />;
    }
};