import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import CustomerLayout from '../../../components/Layouts/CustomerLayout';
import { validateUpdateOrderStatus } from '../../../apiCall/cart'
import Spinner from "../../../components/Spinner";
import { useParams } from "react-router-dom";

const PaymentResult = ({match}) => {
    
    const [showSpinner, setShowSpinner] = useState(false);
    const [validPayment, setValidPayment] = useState(false);
    const [errorPayment, setErrorPayment] = useState(false);
    const [errMessage, setErrorMessage] = useState('');
    const [orderSummaryId, setOrderSummaryId] = useState('');

    const params = useParams();

    useEffect(() => {
        goTop();
        
        setShowSpinner(true);
        
        const summaryId = params.summaryId;
        const queryParams = new URLSearchParams(window.location.search)
        const resultIndicator = queryParams.get("resultIndicator")
        const sessionVersion = queryParams.get("sessionVersion")

        setOrderSummaryId(summaryId);
        
        var request = {
            OrderSummaryId: summaryId,
            ResultIndicator: resultIndicator,
            SessionVersionNo: sessionVersion
        };

        validateUpdateOrderStatus(request).then(res => {
            
            if (res.isSuccess === true) {
                setShowSpinner(false);
                setValidPayment(true);
                setErrorPayment(false);
                setErrorMessage(res.message);
            } 
            else {
                setShowSpinner(false);
                setValidPayment(false);
                setErrorPayment(true);
                setErrorMessage(res.message);
            }
        });

    }, []);

    const goTop = () => {
        window.scrollTo(0, 0);
    };

    const successMessage = () => {
        return (<div className="signup-confirmation vh-70">
            <div className="wrapper-1">
                <div className="wrapper-2">
                    <h1>Order has been successfully Submitted!</h1> 
                    <p>{errMessage}</p>
                    <br /><br />
                    <Link className="go-home" to="/pages/secured/user/my-purchases">Click to view purchases</Link>
                </div>
            </div>
        </div>);
    }

    const errorMessage = () => {
        return (<div className="signup-confirmation vh-70">
            <div className="wrapper-1">
                <div className="wrapper-2">
                    <h1>Error while processing order!</h1> 
                    <h2>{errMessage}</h2> 
                    <br /><br />
                    <Link className="go-home" to={"/pages/secured/user/my-purchases/details/" + orderSummaryId}>Back to Purchase Form</Link>
                </div>
            </div>
        </div>);
    }
    
    return (
        <CustomerLayout>
            <main>
                <div className="container center">
                    <div className="row mb-20">
                        <div className="col-md">
                            { showSpinner && <Spinner /> }
                            { validPayment && successMessage() }
                            { errorPayment && errorMessage() }
                        </div>
                    </div>
                </div>
            </main>
        </CustomerLayout>
    );
};

export default PaymentResult;