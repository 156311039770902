import React, { useState, useEffect } from 'react';
import { Navigate } from "react-router-dom";
import BootstrapTable from 'react-bootstrap-table-next';
import PaginatorFactory from 'react-bootstrap-table2-paginator'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import CustomerLayout from '../../../components/Layouts/CustomerLayout';
import Spinner from '../../../components/Spinner';
import { getPurchase } from '../../../apiCall/cart';

const MyPurchase = () => {

    const [showSpinner, setShowSpinner] = useState(false);
    const [viewDetail, setViewDetail] = useState(false);
    const [data, setData] = useState([]);
    const [values, setValues] = useState({
        summaryid: '',
        referenceNumber: '',
        statusId: 0
    })
    const [showViewDetail, setShowViewDetail] = useState(false);

    const [errors, setErrors] = useState({
        messages: ''
    });

    const [modalMessage, setModalMessage] = useState('');
    const [showConfirmation, setShowConfirmation] = useState(false);
    const handleCloseConfirmation = () => setShowConfirmation(false);

    useEffect(() => {
        getPurchaseList();
        goTop();
    }, []);

    const goTop = () => {
        window.scrollTo(0, 0);
    };

    const getPurchaseList = () => {
        setShowSpinner(true);
        getPurchase().then(res => {
            if (res.isSuccess === true) {
                setErrors("");
                setData(res.dataDictionary.data);
                setShowSpinner(false);
            } 
            else {
                setShowSpinner(false);
                setErrors(res.message);
            }
        })

        
    };

    const columns = [
        { dataField: "referenceNumber", text: "Reference Number", sort: true, headerTitle: true },
        { dataField: "date", text: "Date", sort: true, headerTitle: true },
        { dataField: "firstname", text: "First Name", sort: true, headerTitle: true },
        { dataField: "lastname", text: "Last Name", sort: true, headerTitle: true },
        { dataField: "orderStatus", text: "Order Status", sort: true, headerTitle: true },
        { dataField: "qty", text: "Qty", sort: true, headerTitle: true },
        { dataField: "totalPrice", text: "Total Net Amount", sort: true, headerTitle: true },
        { dataField: "statusId", text: "Status Id", sort: false, headerTitle: false, hidden: true }
        
    ];

    const selectRow = {
        mode: 'radio',
        clickToSelect: true,
        bgColor: '#A6B8A1',
        hideSelectColumn: true,
        onSelect: (row, isSelect, rowIndex, e) => {
            if(isSelect) {
                setValues({
                    summaryid: row.summaryid, 
                    referenceNumber: row.referenceNumber,
                    statusId: row.statusId
                })
            } 
        }
    };

    const renderShowsTotal = (start, to, total) => {
        return (
          <p style={ { color: 'blue' } }>
            Showing Records From { start } to { to } of { total }
          </p>
        );
    }

    const options = {
       defaultSortName: 'date',  // default sort column name
       defaultSortOrder: 'desc',  // default sort order
        page: 1,  // which page you want to show as default
        sizePerPageList: [ {
          text: '5', value: 5
        }, {
            text: '15', value: 15
        }, {
          text: '25', value: 25
        }, {
          text: 'All', value: (data.data) ? data.data.length : 9999999999
        } ], 
        sizePerPage: 10,  // which size per page you want to locate as default
        pageStartIndex: 1, // where to start counting the pages
        paginationSize: 3,  // the pagination bar size.
        prePage: 'Prev', // Previous page button text
        nextPage: 'Next', // Next page button text
        firstPage: 'First', // First page button text
        lastPage: 'Last', // Last page button text
        paginationShowsTotal: renderShowsTotal,  // Accept bool or function
        paginationPosition: 'bottom',  // default is bottom, top and both is all available
        hideSizePerPage: true // You can hide the dropdown for sizePerPage
        // alwaysShowAllBtns: true // Always show next and previous button
        // withFirstAndLast: false > Hide the going to First and Last page button
    };

    const handleViewDetail = () => {
        const { summaryid, referenceNumber, statusId } = values;
        if(statusId === 3 || statusId === 2)
        {
            return <Navigate push to={`/pages/secured/user/cart/${summaryid}`} />; 
        }
        else {
            return <Navigate push to={`/pages/secured/user/my-purchases/details/${summaryid}`} />;
        }   
    }

    const rowEvents = {
        onDoubleClick: (e, row, rowIndex) => {
            
            setValues({
                summaryid: row.summaryid, 
                referenceNumber: row.referenceNumber,
                statusId: row.statusId
            })

            setShowConfirmation(true);
            setModalMessage(`You have selected: ${row.referenceNumber}. Do you want to proceed?`);
        }
    };

    const handleCancel = () => {
        setShowConfirmation(false);
        setShowSpinner(false);
    };

    const handleSubmit = () => {
        setShowConfirmation(false);
        setModalMessage('');
        setViewDetail(true);
    };

    return (
        <CustomerLayout>
            <main>
                <section className="reservation-section">
                    <div className="row mb-12">
                        <div className="col-md">
                            { showSpinner && <Spinner /> }
                            <Modal show={showConfirmation} onHide={handleCloseConfirmation} centered>
                                <Modal.Header closeButton>
                                    <Modal.Title className="font-size-16">Ossuary - Confirmation</Modal.Title>
                                </Modal.Header>
                                <Modal.Body  className="font-size-16">{modalMessage}</Modal.Body>
                                <Modal.Footer>
                                    <Button className="btn bg-color-primary" variant="secondary" onClick={() => { handleSubmit() }}>
                                        OK
                                    </Button>
                                    <Button className="btn bg-color-default" variant="secondary" onClick={() => { handleCancel(); }}>
                                        CANCEL
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                            <div className="row mb-12">
                                <div className="col-md">
                                    <h3 id="warning-message" className="err-msg">{ errors.message }</h3>
                                </div>
                            </div>
                        </div>
                    </div>   
                    <div className="row mb-12">
                        <div className="col-md">
                            <BootstrapTable 
                                keyField='referenceNumber' 
                                data={data} 
                                columns={columns} 
                                striped
                                hover
                                condensed
                                pagination={ PaginatorFactory(options) }
                                selectRow={ selectRow }
                                id="tbl"
                                noDataIndication={ 'No results found' }
                                rowEvents={ rowEvents }
                            /> 

                        </div>
                    </div>    
                </section>
            </main>
            { viewDetail && handleViewDetail() }
        </CustomerLayout>
    );
};

export default MyPurchase;