import React, { useState, useEffect } from 'react';
import { Navigate, useParams } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { getFloor } from '../../../apiCall/floor';
import CustomerLayout from '../../../components/Layouts/CustomerLayout';
import FloorSelection from '../../../components/FloorSelection';
import Spinner from '../../../components/Spinner';

const Floor = () => {

    const [floor, setFloor] = useState([]);
    const [pillar, setPillar] = useState([]);
    const [showSpinner, setShowSpinner] = useState(false);
    const [error, setErrors] = useState("");
    const [display, setDisplay] = useState(false);
    const [layout, setLayout] = useState('');
    const [defaultValue, setDefaultValue] = useState({
        label: '', value: '', floorNumber: ''
    });
    const [redirect, setRedirect] = useState(false);
    const [back, setBack] = useState(false);
    const [show, setShow] = useState(false);

    const [selectedPillar, setSelectedPillar] = useState({
        buildingCode: '',
        floorCode: '',
        pillar: '',
        label: ''
    });

    const params = useParams();

    useEffect(() => {
        
        const buildingCode = params.buildingCode;
        const floorCode = '01';
        const pillar = '';
        const label = '';
        
        if (buildingCode === 'TO1') {
            setLayout('T2');
        } else {
            setLayout('T1');
        }
        setSelectedPillar({ buildingCode, floorCode, pillar, label });
        goTop();
        loadDragJs();
        
    }, []);

    const goTop = () => {
        window.scrollTo(0, 0);
    };

    const loadDragJs = () => {
        
        const script = document.createElement("script");
        script.src = "/js/dragscroll.js";
        script.async = true;
        document.body.appendChild(script);
    };

    const onClickBack = () => {
        setBack(true);
    }

    const getFloorList = (buildingCode) => {
        setShowSpinner(true);
        getFloor(buildingCode).then(res => {

            if (res.isSuccess === true) {
                setShowSpinner(false);
                setErrors("");

                if (res.dataDictionary.data.length > 0) {
                    setFloor(res.dataDictionary.data);

                    setDefaultValue({
                        label: res.dataDictionary.data[0].label,
                        value: res.dataDictionary.data[0].value,
                        floorNumber: res.dataDictionary.data[0].floorNumber
                    });
                    setDisplay(true);
                    
                }
            }
            else {
                setShowSpinner(false);
                setErrors(res.message);
            }
        })
    };

    const handleFloorChange = option => {
        setDisplay(true);
        setSelectedPillar({ ... selectedPillar, floorCode: option.value });
    }

    const handleRedirect = () => {
        const { buildingCode, floorCode, pillar, label } = selectedPillar;
        const encLabel = encodeURI(label)
        var path = `/pages/secured/user/pillar/${encodeURI(buildingCode)}/${encodeURI(floorCode)}/${encodeURI(pillar)}/${encLabel}`
        return <Navigate push to={path} />;
    }

    const redirectToBack = () => {
        var url = '/pages/secured/user/reservation';
        if (back) {
            return <Navigate to={url} />;
        }
    };

    const [showConfirmation, setShowConfirmation] = useState(false);
    const handleCloseConfirmation = () => setShowConfirmation(false);

    const validation = (pillar, label) => {
        setSelectedPillar({ ... selectedPillar, pillar, label });
        setShowConfirmation(true);
    }

    return (
        <CustomerLayout>
            <Modal show={showConfirmation} onHide={handleCloseConfirmation} centered>
                <Modal.Header closeButton>
                    <Modal.Title className="font-size-16">The Ossuary - Pillar Selection</Modal.Title>
                </Modal.Header>
                <Modal.Body className="font-size-16">
                    <p>You have selected: {selectedPillar.label}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn bg-color-primary" variant="secondary" onClick={() => setRedirect(true)}>
                        OK
                    </Button>
                    <Button variant="secondary" onClick={handleCloseConfirmation}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
            <main>
                {showSpinner && <Spinner />}
                <section className="reservation-section">
                    { error && <div className="row mb-12">
                        <div className="col-md">
                            <h3 id="warning-message" className="err-msg">{error}</h3>
                        </div>
                    </div> }
                    
                    <div className="row mt-20">
                        <div className="col-md-12">
                            <FloorSelection data={pillar} template={layout} validation={validation}/>
                        </div>
                    </div>
                    <div className="row mt-20">
                        <div className="col-md-6">
                            <button onClick={onClickBack} className="btn btn--cancel mr-4">Back</button>
                        </div>
                    </div>
                </section>
            </main>
            {redirect && handleRedirect()}
            {redirectToBack()}
        </CustomerLayout>
    );
};

export default Floor;