import React from 'react';
import Layout from '../components/Layouts/Layout'

const columbarium = () => {

    const main = 'https://res.cloudinary.com/fsb-land-holding/image/upload/v1619860597/project01/columbarium/ossuary_landscape_dccovx.png'
    const feature1 = 'https://res.cloudinary.com/fsb-land-holding/image/upload/v1619861795/project01/columbarium/ossuary_landscape_1_r9tugl.png'
    const feature2 = 'https://res.cloudinary.com/fsb-land-holding/image/upload/v1619861797/project01/columbarium/ossuary_landscape_2_i4y9kt.png'
    const feature3 = 'https://res.cloudinary.com/fsb-land-holding/image/upload/v1619861789/project01/columbarium/ossuary_landscape_3_e31hov.png'
    const feature4 = 'https://res.cloudinary.com/fsb-land-holding/image/upload/v1619861795/project01/columbarium/ossuary_landscape_4_vpqqu7.png'
    const feature5 = 'https://res.cloudinary.com/fsb-land-holding/image/upload/v1619861794/project01/columbarium/ossuary_landscape_5_nxcm1y.png'
    const feature6 = 'https://res.cloudinary.com/fsb-land-holding/image/upload/v1619861794/project01/columbarium/ossuary_landscape_6_gn1fsc.png'
    const feature7 = 'https://res.cloudinary.com/fsb-land-holding/image/upload/v1619861794/project01/columbarium/ossuary_landscape_7_ywd3lx.png'
    const feature8 = 'https://res.cloudinary.com/fsb-land-holding/image/upload/v1619861797/project01/columbarium/ossuary_landscape_8_j4hliq.png'

    return (
        <Layout>
            <main>
                {
                    //<div className="footer columbarium mt-120">
                    //    <div className="columbarium__header" >
                    //        {
                    //            // <span className="columbarium__sub">THE OSSUARY</span>
                    //        }
                    //    </div>
                    //</div>
                }
                <div className="container">
                    <div className="row mt-160 mb-40">
                        <div className="col-md text-center">
                            <h1 className="heading-tertiary u-margin-bottom-small">The Ossuary</h1>
                        </div>
                    </div>
                    <div className="row mb-40">
                        <div className="col-md">
                            <a href="#popup">
                                <img className="feature__img" src="https://res.cloudinary.com/fsb-land-holding/image/upload/v1619860597/project01/columbarium/ossuary_landscape_dccovx.png" alt="" />
                            </a>
                        </div>
                        <div className="col-md">
                            <p className="paragraph mt-20">
                                Nestled in the Philippine’s newest hillside resort community of Kahangan Estate Cebu, 
                                The Ossuary is a state-of-the-art columbarium that provides a balance of serenity and technology.​
                                Incorporated with an iconic destination Chapel and nature’s elements, visiting your dearly departed 
                                will truly be a memorable experience everytime.
                            </p>
                        </div>
                    </div>
                    <div className="row mt-40 mb-40">
                        <div className="col-md text-center">
                            <h1 className="heading-tertiary u-margin-bottom-small">Features</h1>
                        </div>
                    </div>
                    <div className="row mb-40">
                        <div className="col-md">
                            <div className="figure">
                                <a href="#popup1">
                                    <img className="figure__img" 
                                    src={feature1} 
                                    alt="" />
                                </a>
                            </div>
                        </div>
                        <div className="col-md">
                            <div className="figure">
                                <a href="#popup2">
                                    <img className="figure__img" 
                                    src={feature2} 
                                    alt="" />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-40">
                        <div className="col-md">
                            <div className="figure">
                                <a href="#popup3">
                                    <img className="figure__img" 
                                    src={feature3} 
                                    alt="" />
                                </a>
                            </div>
                        </div>
                        <div className="col-md">
                            <div className="figure">
                                <a href="#popup4">
                                    <img className="figure__img" 
                                    src={feature4} 
                                    alt="" />
                                </a>
                            </div>
                        </div>
                    </div>

                    <div className="row mb-40">
                        <div className="col-md">
                            <div className="figure">
                                <a href="#popup5">
                                    <img className="figure__img" 
                                    src={feature5} 
                                    alt="" />
                                </a>
                            </div>
                        </div>
                        <div className="col-md">
                            <div className="figure">
                                <a href="#popup6">
                                    <img className="figure__img" 
                                    src={feature6} 
                                    alt="" />
                                </a>
                            </div>
                        </div>
                    </div>

                    <div className="row mb-40">
                        <div className="col-md">
                            <div className="figure">
                                <a href="#popup7">
                                    <img className="figure__img" 
                                    src={feature7} 
                                    alt="" />
                                </a>
                            </div>
                        </div>
                        <div className="col-md">
                            <div className="figure">
                                <a href="#popup8">
                                    <img className="figure__img" 
                                    src={feature8} 
                                    alt="" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <div className="popup" id="popup"><div className="popup__content"><a href="#section" className="popup__close">&times;</a><img className="popup__img" src={main} alt="" /></div></div>
            <div className="popup" id="popup1"><div className="popup__content"><a href="#section" className="popup__close">&times;</a><img className="popup__img" src={feature1} alt="" /></div></div>
            <div className="popup" id="popup2"><div className="popup__content"><a href="#section" className="popup__close-light">&times;</a><img className="popup__img" src={feature2} alt="" /></div></div>
            <div className="popup" id="popup3"><div className="popup__content"><a href="#section" className="popup__close-light">&times;</a><img className="popup__img" src={feature3} alt="" /></div></div>
            <div className="popup" id="popup4"><div className="popup__content"><a href="#section" className="popup__close-light">&times;</a><img className="popup__img" src={feature4} alt="" /></div></div>
            <div className="popup" id="popup5"><div className="popup__content"><a href="#section" className="popup__close-light">&times;</a><img className="popup__img" src={feature5} alt="" /></div></div>
            <div className="popup" id="popup6"><div className="popup__content"><a href="#section" className="popup__close-light">&times;</a><img className="popup__img" src={feature6} alt="" /></div></div>
            <div className="popup" id="popup7"><div className="popup__content"><a href="#section" className="popup__close-light">&times;</a><img className="popup__img" src={feature7} alt="" /></div></div>
            <div className="popup" id="popup8"><div className="popup__content"><a href="#section" className="popup__close-light">&times;</a><img className="popup__img" src={feature8} alt="" /></div></div>
        </Layout>
    );
};

export default columbarium;