import React, { useState, useEffect } from 'react';
import { Navigate } from "react-router-dom";
import CustomerLayout from '../../../components/Layouts/CustomerLayout';
import Spinner from '../../../components/Spinner';

import { getCustomerDetail, updateCustomerDetail } from "../../../apiCall/customer";

function MyProfile() {
    
    const [values, setValues] = useState({
        firstName: '',
        middleName: '',
        lastName: '',
        email: '',
        contactNumber: '',
        password: '',
        confirmPassword: '',
        success: false, 
        error: false
    });

    const [errors, setErrors] = useState({
        firstName: '',
        middleName: '',
        lastName: '',
        email: '',
        contactNumber: '',
        password: '',
        confirmPassword: '',
        messages: ''
    });

    const [showSpinner, setShowSpinner] = useState(false);
    const [cancel, setCancel] = useState(false);
    const [submit, setSubmit] = useState(false);
    
    useEffect(() => {
        goTop();
        getCustomer();
    }, []);

    const goTop = () => {
        window.scrollTo(0, 0);
    };

    const getCustomer = () => {
        setShowSpinner(true);
        getCustomerDetail().then(res => {
            if (res.isSuccess === true) {
                setShowSpinner(false);
                setErrors("");
                setValues({
                    ...values,
                    firstName: res.dataDictionary.firstName,
                    middleName: res.dataDictionary.middleName,
                    lastName: res.dataDictionary.lastName,
                    email: res.dataDictionary.emailAddress,
                    contactNumber: res.dataDictionary.contactNumber,
                });
            } 
            else {
                setShowSpinner(false);
                setErrors({...errors, message: res.message });
            }
        })
    };

    const handleChange = name => event => {
        setValues({ ...values, error: false, [name]: event.target.value });
        if(name === 'firstName') {
            setErrors({...errors, firstName: '', message: '' });
        } 

        if(name === 'lastName') {
            setErrors({...errors, lastName: '', message: '' });
        }

        if(name === 'email') {
            setErrors({...errors, email: '', message: '' });
        }

        if(name === 'contactNumber') {
            setErrors({...errors, contactNumber: '', message: '' });
        }

        if(name === 'password') {
            setErrors({...errors, password: '', message: '' });
        }

        if(name === 'confirmPassword') {
            setErrors({...errors, confirmPassword: '', message: '' });
        }
    };

    const clickCancel = () => {
        setCancel(true);
    };

    const handleCancel = () => {
        var path = `/pages/secured/user/reservation`
        return <Navigate push to={path} />; 
    }

    const handleSubmit = () => {
        var path = `/pages/secured/user/profile/acknowledgement`
        return <Navigate push to={path} />; 
    }
    
    const clickSave = () => {
        setShowSpinner(true);

        const { firstName, middleName, lastName, contactNumber, password, confirmPassword } = values;
        
        const request = {
            FirstName: firstName,
            MiddleName: middleName,
            LastName: lastName,
            ContactNumber: contactNumber,
            Password: password,
            ConfirmPassword: confirmPassword
        }

        updateCustomerDetail(request).then(res => {
            
            if (res.isSuccess === true) {
                setShowSpinner(false);
                setErrors("");
                setSubmit(true);
            } 
            else {
                setShowSpinner(false);
                setErrors({...errors, message: res.message });
            }
        })
    };

    return (
        <CustomerLayout>
            <main>
                <section className="reservation-section">
                    <div className="row mb-12">
                        <h2 className="ml-4">My Profile</h2>
                    </div>
                    <div className="row mb-12">
                        <div className="col-md">
                            { showSpinner && <Spinner /> }
                            <div className="row mb-12">
                                <div className="col-md">
                                    <h3 id="warning-message" className="err-msg">{ errors.message }</h3>
                                </div>
                            </div>

                            <div className="row mb-12">
                                <div className="col-md">
                                    <label className="font-size-16">EMAIL ADDRESS</label>
                                    <input type="email" className="form-control font-size-14 readonly" onChange={handleChange('email')} value={ values.email } disabled />
                                    <div className="err-msg" style={{ display: errors.email ? '' : 'none' }}>
                                        <span>{errors.email}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-12">
                                <div className="col-md">
                                    <label className="font-size-16">FIRST NAME</label>
                                    <input type="text" className="form-control font-size-14" onChange={handleChange('firstName')} value={ values.firstName } />
                                    <div className="err-msg" style={{ display: errors.firstName ? '' : 'none' }}>
                                        <span>{errors.firstName}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-12">
                                <div className="col-md">
                                    <label className="font-size-16">MIDDLE NAME</label>
                                    <input type="text" className="form-control font-size-14" onChange={handleChange('middleName')} value={ values.middleName } />
                                    <div className="err-msg" style={{ display: errors.middleName ? '' : 'none' }}>
                                        <span>{errors.middleName}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-12">
                                <div className="col-md">
                                    <label className="font-size-16">LAST NAME</label>
                                    <input type="text" className="form-control font-size-14" onChange={handleChange('lastName')} value={ values.lastName } />
                                    <div className="err-msg" style={{ display: errors.lastName ? '' : 'none' }}>
                                        <span>{errors.lastName}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-20">
                                <div className="col-md">
                                    <label className="font-size-16">CONTACT NUMBER</label>
                                    <input type="text" className="form-control font-size-14" onChange={handleChange('contactNumber')} value={ values.contactNumber } />
                                    <div className="err-msg" style={{ display: errors.contactNumber ? '' : 'none' }}>
                                        <span>{errors.contactNumber}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-20">
                                <div className="col-md-6">
                                    <label className="font-size-16">PASSWORD</label>
                                    <input type="password" className="form-control font-size-14" onChange={handleChange('password')} value={ values.password } />
                                    <div className="err-msg" style={{ display: errors.password ? '' : 'none' }}>
                                        <span>{errors.password}</span>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <label className="font-size-16">CONFIRM PASSWORD</label>
                                    <input type="password" className="form-control font-size-14" onChange={handleChange('confirmPassword')} value={ values.confirmPassword } />
                                    <div className="err-msg" style={{ display: errors.confirmPassword ? '' : 'none' }}>
                                        <span>{errors.confirmPassword}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-10">
                                <div className="col-md right">
                                    <button onClick={clickCancel} className="btn btn--cancel mr-5">Cancel</button>
                                    <button onClick={clickSave} className="btn btn--primary">Save</button>
                                </div>
                            </div>
                        </div>
                    </div>    
                </section>
            </main>
            { cancel && handleCancel() }
            { submit && handleSubmit() }
        </CustomerLayout>
    );
}

export default MyProfile