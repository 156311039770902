
import React from 'react';
import { Link } from "react-router-dom";

const BurgerMenu = () => {
    return (
        <div className="navigation">
            <input type="checkbox" className="navigation__checkbox" id="navi-toggle" />
            <label htmlFor="navi-toggle" className="navigation__button">
                <span className="navigation__icon">&nbsp;</span>
            </label>
            <div className="navigation__background">&nbsp;</div>
            <nav className="navigation__nav">
                <ul className="navigation__list">
                    <li className="navigation__item"><Link to={'/pages/columbarium'} className="navigation__link"><span>01</span>The Columbarium</Link></li>
                    <li className="navigation__item"><Link to={'/pages/products'} className="navigation__link"><span>02</span>Vaults and Products</Link></li>
                    <li className="navigation__item"><Link to={'/pages/services'} className="navigation__link"><span>03</span>Services</Link></li>
                    <li className="navigation__item"><Link to={'/pages/afterSales'} className="navigation__link"><span>04</span>After Sales</Link></li>
                    <li className="navigation__item"><Link to={'/pages/login'} className="navigation__link"><span>05</span>Reservation and Purchase</Link></li>
                    <li className="navigation__item"><Link to={'/pages/contact'} className="navigation__link"><span>06</span>Contact Us</Link></li>
                    {
                        //<li className="navigation__item"><Link to={'/pages/faq'} className="navigation__link"><span>07</span>FAQs</Link></li>
                    }
                </ul>
            </nav>
        </div>
    );
};

export default BurgerMenu;