import React from 'react';
import CustomerLayout from '../../../components/Layouts/CustomerLayout';

const OrderPayment = () => {
    
    const clickProceed = () => {
        
    };

    return (
        <CustomerLayout>
            <main>
                <section className="reservation-section">
                    <div className="row mb-12">
                        <h2 className="ml-4">Thank you</h2>
                    </div>
                    <div className="row mb-12">
                        <p className="ml-4 font-size-14">--- Instruction here ---</p>
                    </div>
                    <div className="row">
                        <div className="col-md right">
                            <button onClick={clickProceed} className="btn btn--primary">Proceed</button>
                        </div>
                    </div>
                </section>
            </main>
        </CustomerLayout>
    );
};

export default OrderPayment;