import React, { Fragment, useState, useEffect } from 'react';
import CustomerLayout from '../../../components/Layouts/CustomerLayout';
import { Navigate, useParams } from "react-router-dom";
import { getOrderPreview, getOrderSummaryDetail, resendPurchaseForm } from '../../../apiCall/cart'
import Spinner from '../../../components/Spinner'
import Select from 'react-select';
import DatePicker from "react-datepicker";
import Alert from 'react-bootstrap/Alert';
import * as moment from 'moment';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { submitOrder } from "../../../apiCall/cart"
import { checkoutPage } from "../../../apiCall/mpgs";

import OrderPaymentOption20 from '../../../components/OrderPaymentOption20';
import OrderPaymentOption30 from '../../../components/OrderPaymentOption30';
import OrderPaymentOption50 from '../../../components/OrderPaymentOption50';
import OrderPaymentOptionFullPayment from '../../../components/OrderPaymentOptionFullPayment';
import OrderPaymentOptionReservation from '../../../components/OrderPaymentOptionReservation';

const PurchaseDetails = () => {

    const [showSpinner, setShowSpinner] = useState(false);
    const [data, setData] = useState([]);
    const [totalContractAmount, setTotalContractAmount] = useState('0.00');
    const [totalVATAmount, setTotalVATAmount] = useState('0.00');
    const [totalContractWithVATAmount, setTotalContractWithVATAmount] = useState('0.00');
    const [totalVaultNetAmount, setTotalVaultNetAmount] = useState('0.00');
    const [totalCheckoutAmount, setTotalCheckoutAmount] = useState('0.00');
    const [totalRemainingBalance, setTotalRemainingBalance] = useState('0.00');
    const [totalDiscountAmount, setTotalDiscountAmount] = useState('0.00');
    const [summaryId, setSummaryId] = useState('');
    const [paymentOptions, setPaymentOptions] = useState([]);
    const [modalMessage, setModalMessage] = useState('');
    const [displayPayment, setDisplayPayment] = useState(false);
    
    const [showConfirmation, setShowConfirmation] = useState(false);
    const handleCloseConfirmation = () => setShowConfirmation(false);

    const [back, setBack] = useState(false);
    const [continuePurchase, setContinuePurchase] = useState(false);
    const [actionType, setActionType] = useState('save');
    
    const [values, setValues] = useState({
        firstName: '',
        middleName: '',
        lastName: '',
        homeAddress: '',
        nationality: '',
        dtBirth: new Date(),
        sex: '',
        civilStatus: '',
        preferredMailingAddress: '',
        email: '',
        contactNumber: '',
        employer: '',
        officeAddress: '',
        officePhone: '',
        jobTitle: '',
        natureOfBusiness: '',
        accept: false,
        success: false, 
        error: false,
        message: '',
        referenceNumber: '', 
        orderStatus: '',
        orderStatusId: '',
        salesAgent: ''
    });

    const [errors, setErrors] = useState({
        firstName: '',
        middleName: '',
        lastName: '',
        homeAddress: '',
        dtBirth: '',
        nationality: '',
        sex: '',
        civilStatus: '',
        preferredMailingAddress: '',
        contactNumber: '',
        email: '',
        accept: '',
        messages: ''
    });

    const genders = [
        { value: 'M', label: 'Male' },
        { value: 'F', label: 'Female' }
    ]

    const civilStatuses = [
        { value: 'S', label: 'Single' },
        { value: 'M', label: 'Married' },
        { value: 'D', label: 'Divorced' },
        { value: 'W', label: 'Widowed' }
    ]

    const params = useParams();
    
    useEffect(() => {
        init();
        goTop();
    }, []);

    const goTop = () => {
        window.scrollTo(0, 0);
    };

    const init = () => {
        const summaryId = params.summaryId;
        getOrderDetail(summaryId);
        getSummaryDetail(summaryId);
        setSummaryId(summaryId);
    }

    const getOrderDetail = (summaryId) => {
        setShowSpinner(true);
        getOrderPreview(summaryId).then(res => {
            if (res.isSuccess === true) {
                
                setShowSpinner(false);
                setErrors({...errors, message: '' });
                setData(res.dataDictionary.data);
                setTotalContractAmount(res.dataDictionary.totalContractAmount);
                setTotalVATAmount(res.dataDictionary.totalVATAmount);
                setTotalContractWithVATAmount(res.dataDictionary.totalContractWithVATAmount);
                setTotalVaultNetAmount(res.dataDictionary.totalVaultNetAmount);
                setTotalCheckoutAmount(res.dataDictionary.totalCheckoutAmount);
                setTotalRemainingBalance(res.dataDictionary.totalRemainingBalance);
                setTotalDiscountAmount(res.dataDictionary.totalDiscountAmount);
                setPaymentOptions(res.dataDictionary.paymentOptions);
            } 
            else {
                setShowSpinner(false);
                setErrors({...errors, message: res.message });
            }
        })
    };

    const getSummaryDetail = (summaryId) => {
        setShowSpinner(true);
        getOrderSummaryDetail(summaryId).then(res => {
            if (res.isSuccess === true) {
                var resFirstName = res.dataDictionary.data.firstName;
                var resMiddleName = res.dataDictionary.data.middleName;
                var resLastName = res.dataDictionary.data.lastName;
                var refHomeAddress = res.dataDictionary.data.homeAddress;
                var resNationality = res.dataDictionary.data.nationality;
                var resDtBirth = res.dataDictionary.data.dtBirth;
                var resCivilStatus = res.dataDictionary.data.civilStatus;
                var resGender = res.dataDictionary.data.gender;
                var resPreferredMailingAddress = res.dataDictionary.data.preferredMailingAddress;
                var refEmail = res.dataDictionary.data.emailAddress;
                var refContactNumber = res.dataDictionary.data.contactNumber;
                var refEmployer = res.dataDictionary.data.employerName;
                var refOfficeAddress = res.dataDictionary.data.officeAddress;
                var refOfficePhone = res.dataDictionary.data.officePhoneNo;
                var refJobTitle = res.dataDictionary.data.jobTitle;
                var refNatureOfBusiness = res.dataDictionary.data.natureOfBusiness;
                var refReferenceNumber = res.dataDictionary.data.referenceNum;
                var refOrderStatus = res.dataDictionary.data.orderStatus.orderStatusName;
                var refOrderStatusId = res.dataDictionary.data.orderStatusId;
                var resSalesAgent = res.dataDictionary.data.salesAgent;

                setValues({
                    ...values,
                    firstName: (resFirstName  === null || resFirstName === 'undefined') ? '' : resFirstName,
                    middleName: (resMiddleName  === null || resMiddleName === 'undefined') ? '' : resMiddleName,
                    lastName: (resLastName  === null || resLastName === 'undefined') ? '' : resLastName,
                    homeAddress: (refHomeAddress  === null || refHomeAddress === 'undefined') ? '' : refHomeAddress,
                    nationality: (resNationality  === null || resNationality === 'undefined') ? '' : resNationality,
                    dtBirth: (resDtBirth  === null || resDtBirth === 'undefined') ? '' : new Date(moment(resDtBirth).format('L')),
                    sex: (resGender  === null || resGender === 'undefined') ? '' : resGender,
                    civilStatus: (resCivilStatus  === null || resCivilStatus === 'undefined') ? '' : resCivilStatus,
                    preferredMailingAddress: (resPreferredMailingAddress  === null || resPreferredMailingAddress === 'undefined') ? '' : resPreferredMailingAddress,
                    email: (refEmail  === null || refEmail === 'undefined') ? '' : refEmail,
                    contactNumber: (refContactNumber  === null || refContactNumber === 'undefined') ? '' : refContactNumber,
                    employer: (refEmployer  === null || refEmployer === 'undefined') ? '' : refEmployer,
                    officeAddress: (refOfficeAddress  === null || refOfficeAddress === 'undefined') ? '' : refOfficeAddress,
                    officePhone: (refOfficePhone  === null || refOfficePhone === 'undefined') ? '' : refOfficePhone,
                    jobTitle: (refJobTitle  === null || refJobTitle === 'undefined') ? '' : refJobTitle,
                    natureOfBusiness: (refNatureOfBusiness  === null || refNatureOfBusiness === 'undefined') ? '' : refNatureOfBusiness,
                    referenceNumber: (refReferenceNumber  === null || refReferenceNumber === 'undefined') ? '' : refReferenceNumber,
                    orderStatus: (refOrderStatus  === null || refOrderStatus === 'undefined') ? '' : refOrderStatus,
                    orderStatusId: (refOrderStatusId  === null || refOrderStatusId === 'undefined') ? '' : refOrderStatusId,
                    salesAgent: (resSalesAgent  === null || resSalesAgent === 'undefined') ? '' : resSalesAgent
                });

                if(res.dataDictionary.data.orderStatusId === 2 || res.dataDictionary.data.orderStatusId === 3 || res.dataDictionary.data.orderStatusId === 4 ) setDisplayPayment(true);
                else
                    setDisplayPayment(false);

                setShowSpinner(false);
            } 
            else {
                setShowSpinner(false);
                setErrors({...errors, message: res.message });
            }
        })
    };

    const formatCurrency = (value) => {
        return Number(value).toLocaleString("en-US", {
            style: "currency",
            currency: "PHP"
            
        });
    }

    const handleSubmit = () => {

        setShowConfirmation(false);
        setShowSpinner(true);
        setModalMessage('');

        handleSend();

        goTop();

    };

    const handleCancel = () => {
        setShowConfirmation(false);
        setShowSpinner(false);
    };

    const onClickBack = () => {
        setBack(true);
    }

    const handleRedirect = () => {
        
        if (back) {
            var path ='/pages/secured/user/my-purchases'
            return <Navigate push to={path} />;   
        }
    }

    const handleContinuePurchase = () => {
        
        if (continuePurchase) {
            var path = `/pages/secured/user/cart-continue/${summaryId}`
            return <Navigate push to={path} />;  
        }
    }

    const onClickSendEmail = () => {
        setShowConfirmation(true);
        setModalMessage('Do you really want to send online purchase form?');
        setActionType('send');
    }

    const onClickContinuePurchase = () => {
        setContinuePurchase(true);
    }

    const handleChange = name => event => {
        
        setValues({ ...values, error: false, [name]: event.target.value });

        if(name === 'firstName') {
            setErrors({...errors, firstName: '', message: '' });
        } 

        if(name === 'middleName') {
            setErrors({...errors, middleName: '', message: '' });
        }

        if(name === 'lastName') {
            setErrors({...errors, lastName: '', message: '' });
        }

        if(name === 'homeAddress') {
            setErrors({...errors, homeAddress: '', message: '' });
        }

        if(name === 'nationality') {
            setErrors({...errors, nationality: '', message: '' });
        }

        if(name === 'preferredMailingAddress') {
            setErrors({...errors, preferredMailingAddress: '', message: '' });
        }

        if(name === 'contactNumber') {
            setErrors({...errors, contactNumber: '', message: '' });
        }

        if(name === 'email') {
            setErrors({...errors, email: '', message: '' });
        }
    };

    const handleGenderChange = option => {
        setErrors({...errors, sex: '', message: '' });
        setValues({
            ...values,
            sex: option.value
        });
    }

    const handleCivilStatusChange = option => {
        setErrors({...errors, civilStatus: '', message: '' });
        setValues({
            ...values,
            civilStatus: option.value
        });
    }

    const handleDtBirthchange = (date) => {
        setErrors({...errors, dtBirth: '', message: '' });
        setValues({ ...values, dtBirth: date });
    }

    const handleSend = () => {
        var request = {
            OrderSummaryId: summaryId, 
            FirstName: values.firstName, 
            MiddleName: values.middleName, 
            LastName: values.lastName, 
            HomeAddress: values.homeAddress,
            Nationality: values.nationality,
            DtBirth: values.dtBirth,
            Gender: values.sex,
            CivilStatus: values.civilStatus,
            PreferredMailingAddress: values.preferredMailingAddress,
            EmailAddress: values.email, 
            ContactNumber: values.contactNumber, 
            Employer: values.employer,
            OfficeAddress: values.officeAddress,
            OfficePhoneNo: values.officePhone,
            JobTitle: values.jobTitle,
            NatureOfBusiness: values.natureOfBusiness
        };
        
        resendPurchaseForm(request).then(res => {
            if (res.isSuccess === true) {
                
                setValues({
                    ...values,
                    message: 'Sent successfully.',
                    success: true
                });

                res && goTop();
                res && setShowSpinner(false);
                
            } 
            else {
                setShowSpinner(false);
                setErrors({
                    message: res.message
                });
                res && goTop();
            }
        });
    }

    const clickCheckout = () => {
        
        setShowSpinner(true);
        
        var request = {
            OrderSummaryId: summaryId, 
            FirstName: values.firstName, 
            MiddleName: values.middleName, 
            LastName: values.lastName, 
            HomeAddress: values.homeAddress,
            Nationality: values.nationality,
            DtBirth: values.dtBirth,
            Gender: values.sex,
            CivilStatus: values.civilStatus,
            PreferredMailingAddress: values.preferredMailingAddress,
            EmailAddress: values.email, 
            ContactNumber: values.contactNumber, 
            Employer: values.employer,
            OfficeAddress: values.officeAddress,
            OfficePhoneNo: values.officePhone,
            JobTitle: values.jobTitle,
            NatureOfBusiness: values.natureOfBusiness,
            Accept: true
        };

        submitOrder(request).then(res => {

            if (res.isSuccess === true) {
                setShowSpinner(false);
                setErrors({...errors, message: '' });

                if(res.dataDictionary !== "undefined" && res.dataDictionary !== null) {
                    
                    var merchantid = res.dataDictionary.merchantid &&  res.dataDictionary.merchantid;
                    var totalPrice = res.dataDictionary.totalPrice &&  res.dataDictionary.totalPrice;
                    var productName = res.dataDictionary.productName &&  res.dataDictionary.productName;
                    var orderid = res.dataDictionary.orderid &&  res.dataDictionary.orderid;
                    var checkoutSessionId = res.dataDictionary.checkoutSessionId &&  res.dataDictionary.checkoutSessionId;

                    checkoutPage(merchantid, totalPrice, productName, orderid, checkoutSessionId);
                }
                else {
                    setErrors({
                        message: 'Missing Data Dictionary Data'
                    });
                }
            } 
            else {
                setShowSpinner(false);

                if(res.dataDictionary !== "undefined" && res.dataDictionary !== null) {
                    setErrors({
                        firstName: res.dataDictionary.firstName &&  res.dataDictionary.firstName,
                        middleName: res.dataDictionary.middleName &&  res.dataDictionary.middleName,
                        lastName: res.dataDictionary.lastName &&  res.dataDictionary.lastName,
                        homeAddress: res.dataDictionary.homeAddress &&  res.dataDictionary.homeAddress,
                        nationality: res.dataDictionary.nationality &&  res.dataDictionary.nationality,
                        dtBirth: res.dataDictionary.dtBirth &&  res.dataDictionary.dtBirth,
                        sex: res.dataDictionary.gender &&  res.dataDictionary.gender,
                        civilStatus: res.dataDictionary.civilStatus &&  res.dataDictionary.civilStatus,
                        preferredMailingAddress: res.dataDictionary.preferredMailingAddress &&  res.dataDictionary.preferredMailingAddress,
                        email: res.dataDictionary.email &&  res.dataDictionary.email,
                        contactNumber: res.dataDictionary.contactNumber &&  res.dataDictionary.contactNumber
                    });
                } else {
                    setErrors({
                        message: res.message
                    });
                }
            }
        })
    };

    return (
        <CustomerLayout>
            <Modal show={showConfirmation} onHide={handleCloseConfirmation} centered>
                <Modal.Header closeButton>
                    <Modal.Title className="font-size-16">The Ossuary - Confirmation</Modal.Title>
                </Modal.Header>
                <Modal.Body  className="font-size-16">{modalMessage}</Modal.Body>
                <Modal.Footer>
                    <Button className="btn bg-color-primary" variant="secondary" onClick={() => { handleSubmit() }}>
                        OK
                    </Button>
                    <Button className="btn bg-color-default" variant="secondary" onClick={() => { handleCancel(); }}>
                        CANCEL
                    </Button>
                </Modal.Footer>
            </Modal>
            { showSpinner && <Spinner /> }
            <main>
                <section className="reservation-section">
                    <div className="row mb-12">
                        <div className="col-md">
                            { errors.message && <Alert key='danger' variant='danger' className="font-size-14">
                                { errors.message }
                            </Alert>}
                            { values.success && <Alert key='success' variant='success' className="font-size-14">
                                { values.message }
                            </Alert> } 
                        </div>
                    </div>
                    <div className="row mb-12">
                        <h2 className="ml-4">Customer Forms</h2>
                    </div>
                    <div className="row mb-18">
                        <div className="col-md">
                            <label className="font-size-14"><b>FIRST NAME:</b></label>
                            <input type="text" className="form-control font-size-14 height-36" onChange={handleChange('firstName')} value={ values.firstName } readOnly={!displayPayment}/>
                            <div className="err-msg font-size-12" style={{ display: errors.firstName ? '' : 'none' }}>
                                <span>{errors.firstName}</span>
                            </div>
                        </div>
                        <div className="col-md">
                            <label className="font-size-14"><b>MIDDLE NAME:</b></label>
                            <input type="text" className="form-control font-size-14 height-36" onChange={handleChange('middleName')} value={ values.middleName } readOnly={!displayPayment}/>
                            <div className="err-msg font-size-12" style={{ display: errors.middleName ? '' : 'none' }}>
                                <span>{errors.middleName}</span>
                            </div>
                        </div>
                        <div className="col-md">
                            <label className="font-size-14"><b>LAST NAME:</b></label>
                            <input type="text" className="form-control font-size-14 height-36" onChange={handleChange('lastName')} value={ values.lastName } readOnly={!displayPayment}/>
                            <div className="err-msg font-size-12" style={{ display: errors.lastName ? '' : 'none' }}>
                                <span>{errors.lastName}</span>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-18">
                        <div className="col-md">
                            <label className="font-size-14"><b>HOME ADDRESS (No., Street, Bldg. Name, Town / City, County, Province / State, Country, Zip Code):</b></label>
                            <input type="text" className="form-control font-size-14 height-36" onChange={handleChange('homeAddress')} value={ values.homeAddress } readOnly={!displayPayment}/>
                            <div className="err-msg font-size-12" style={{ display: errors.homeAddress ? '' : 'none' }}>
                                <span>{errors.homeAddress}</span>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-18">
                        <div className="col-md">
                            <label className="font-size-14"><b>DATE OF BIRTH:</b></label>
                            <DatePicker
                                className="form-control font-size-14 height-36"
                                selected={values.dtBirth}
                                onChange={(date) => handleDtBirthchange(date) }
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                readOnly={!displayPayment}
                            />
                            <div className="err-msg font-size-12" style={{ display: errors.dtBirth ? '' : 'none' }}>
                                <span>{errors.dtBirth}</span>
                            </div>
                        </div>
                        <div className="col-md">
                            <label className="font-size-14"><b>NATIONALITY:</b></label>
                            <input type="text" className="form-control font-size-14 height-36" onChange={handleChange('nationality')} value={ values.nationality } readOnly={!displayPayment} />
                            <div className="err-msg font-size-12" style={{ display: errors.nationality ? '' : 'none' }}>
                                <span>{errors.nationality}</span>
                            </div>
                        </div>
                        <div className="col-md">
                            <label className="font-size-14"><b>SEX:</b></label>
                            <Select 
                                className="font-size-14"
                                options={genders} 
                                onChange={handleGenderChange} 
                                value={genders.filter(function(option) {
                                    return option.value === values.sex;
                                })}
                                isDisabled={!displayPayment}
                            />
                            <div className="err-msg font-size-12" style={{ display: errors.sex ? '' : 'none' }}>
                                <span>{errors.sex}</span>
                            </div>
                        </div>
                        <div className="col-md">
                            <label className="font-size-14"><b>CIVIL STATUS:</b></label>
                            <Select 
                                className="font-size-14"
                                options={civilStatuses} 
                                onChange={handleCivilStatusChange} 
                                value={civilStatuses.filter(function(option) {
                                    return option.value === values.civilStatus;
                                })}
                                isDisabled={!displayPayment}
                            />
                            <div className="err-msg font-size-12" style={{ display: errors.civilStatus ? '' : 'none' }}>
                                <span>{errors.civilStatus}</span>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-18">
                        <div className="col-md">
                            <label className="font-size-14"><b>PREFERRED MAILING ADDRESS:</b></label>
                            <input type="text" className="form-control font-size-14 height-36" onChange={handleChange('preferredMailingAddress')} value={ values.preferredMailingAddress } readOnly={!displayPayment} />
                            <div className="err-msg font-size-12" style={{ display: errors.preferredMailingAddress ? '' : 'none' }}>
                                <span>{errors.preferredMailingAddress}</span>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-26">
                        <div className="col-md">
                            <label className="font-size-14"><b>MOBILE NO.:</b></label>
                            <input type="text" className="form-control font-size-14 height-36" onChange={handleChange('contactNumber')} value={ values.contactNumber } readOnly={!displayPayment} />
                            <div className="err-msg font-size-12" style={{ display: errors.contactNumber ? '' : 'none' }}>
                                <span>{errors.contactNumber}</span>
                            </div>
                        </div>
                        <div className="col-md">
                            <label className="font-size-14"><b>EMAIL ADDRESS:</b></label>
                            <input type="email" className="form-control font-size-14 height-36" onChange={handleChange('email')} value={ values.email } readOnly={!displayPayment} />
                            <div className="err-msg font-size-12" style={{ display: errors.email ? '' : 'none' }}>
                                <span>{errors.email}</span>
                            </div>
                        </div>
                        <div className="col-md">
                            <label className="font-size-14"><b>SALES AGENT:</b></label>
                            <input type="email" className="form-control font-size-14 height-36" onChange={handleChange('salesAgent')} value={ values.salesAgent } readOnly={!displayPayment} />
                            <div className="err-msg font-size-12" style={{ display: errors.salesAgent ? '' : 'none' }}>
                                <span>{errors.salesAgent}</span>
                            </div>
                        </div> 
                    </div>
                    <div className="row mb-30">
                        <div className="col-md">
                            <h2>EMPLOYMENT INFORMATION</h2>
                        </div>
                    </div>
                    <div className="row mb-18">
                        <div className="col-md">
                            <label className="font-size-14"><b>EMPLOYER/BUSINESS NAME:</b></label>
                            <input type="text" className="form-control font-size-14 height-36" onChange={handleChange('employer')} value={ values.employer } readOnly={!displayPayment} />
                            <div className="err-msg" style={{ display: errors.employer ? '' : 'none' }}>
                                <span>{errors.employer}</span>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-18">
                        <div className="col-md">
                            <label className="font-size-14"><b>OFFICE ADDRESS (No., Street, Bldg. Name, Town / City, County, Province / State, Country, Zip Code):</b></label>
                            <input type="text" className="form-control font-size-14 height-36" onChange={handleChange('officeAddress')} value={ values.officeAddress } readOnly={!displayPayment} />
                            <div className="err-msg" style={{ display: errors.officeAddress ? '' : 'none' }}>
                                <span>{errors.officeAddress}</span>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-18">
                        <div className="col-md">
                            <label className="font-size-14"><b>OFFICE PHONE NO.:</b></label>
                            <input type="text" className="form-control font-size-14 height-36" onChange={handleChange('officePhone')} value={ values.officePhone } readOnly={!displayPayment} />
                            <div className="err-msg" style={{ display: errors.officePhone ? '' : 'none' }}>
                                <span>{errors.officePhone}</span>
                            </div>
                        </div>
                        <div className="col-md">
                            <label className="font-size-14"><b>JOB TITLE:</b></label>
                            <input type="text" className="form-control font-size-14 height-36" onChange={handleChange('jobTitle')} value={ values.jobTitle } readOnly={!displayPayment} />
                            <div className="err-msg" style={{ display: errors.jobTitle ? '' : 'none' }}>
                                <span>{errors.jobTitle}</span>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-20">
                        <div className="col-md">
                            <label className="font-size-14"><b>NATURE OF BUSINESS:</b></label>
                            <input type="text" className="form-control font-size-14 height-36" onChange={handleChange('natureOfBusiness')} value={ values.natureOfBusiness } readOnly={!displayPayment} />
                            <div className="err-msg" style={{ display: errors.natureOfBusiness ? '' : 'none' }}>
                                <span>{errors.natureOfBusiness}</span>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-12">
                        <h2 className="ml-4">Order Details</h2>
                    </div>
                    <div className="row mb-18">
                        <div className="col-md-6">
                            <label className="font-size-14"><b>REFERENCE NUMBER:</b></label>
                            <input type="text" className="form-control font-size-14 height-36" value={ values.referenceNumber } readOnly />
                        </div>
                        <div className="col-md-6">
                            <label className="font-size-14"><b>ORDER STATUS:</b></label>
                            <input type="text" className="form-control font-size-14 height-36" value={ values.orderStatus } readOnly />
                        </div>
                    </div>
                    <div className="row mb-12">
                        <div className="col-md">
                            <table className="table table-success table-striped font-size-12">
                                <thead>
                                    <tr>
                                        <th scope="col">Seq</th>
                                        <th scope="col">Vault</th>
                                        <th scope="col">Location</th>
                                        <th scope="col">Vault Price</th>
                                        <th scope="col">Payment Option</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map((product, idx) =>
                                        <tr key={idx}>
                                            <td className="td-width-150">
                                                {idx + 1}
                                            </td>
                                            <td className="td-width-150">
                                                {product.vaultCode}
                                                <h4>{product.vaultStatusName}</h4>
                                            </td>
                                            <td className="td-width-150">
                                                {product.building} <br /> {product.floor} <br /> {product.pillarName} {product.level}{product.column}
                                            </td>
                                            <td className="td-width-250">
                                                Vault Price: <span className="span-right">{formatCurrency(product.vaultSellingPrice)}</span> <br />
                                            </td>
                                            <td>
                                            {
                                                paymentOptions.map((paymentOption, id2) =>
                                                    <Fragment  key={id2}>
                                                        <OrderPaymentOptionFullPayment paymentOption={paymentOption} product={product} formatCurrency={formatCurrency} />
                                                        <OrderPaymentOption20 paymentOption={paymentOption} product={product} formatCurrency={formatCurrency} />
                                                        <OrderPaymentOption30 paymentOption={paymentOption} product={product} formatCurrency={formatCurrency} />
                                                        <OrderPaymentOption50 paymentOption={paymentOption} product={product} formatCurrency={formatCurrency} />
                                                        <OrderPaymentOptionReservation paymentOption={paymentOption} product={product} formatCurrency={formatCurrency} />
                                                    </Fragment>
                                                )
                                            }
                                            </td>
                                        </tr>
                                            
                                    )}
                                    <tr>
                                        <th colSpan="3" scope="col">Amount Due for Checkout</th>
                                        <th scope="col">
                                            <span className="span-right font-size-16">{formatCurrency(totalCheckoutAmount)}</span>
                                        </th>
                                        <th scope="col">&nbsp;</th>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="row mb-12 summary">
                        <div className="col-md right">
                            { values.orderStatusId === 1 && <button onClick={onClickSendEmail} className="btn btn--primary mr-4">Send Purchase Form</button> }
                            { values.orderStatusId === 9 && <button onClick={onClickSendEmail} className="btn btn--primary mr-4">Send Purchase Form</button> }
                            { values.orderStatusId === 9 && <button onClick={onClickContinuePurchase} className="btn btn--primary mr-4">Continue Purchase</button> }
                            { values.orderStatusId === 10 && <button onClick={onClickContinuePurchase} className="btn btn--primary mr-4">Continue Purchase</button> }
                            { displayPayment && <button onClick={clickCheckout} className="btn btn--primary mr-4">Submit &amp; Checkout</button> }
                            <button onClick={onClickBack} className="btn btn--cancel mr-4">Back</button>
                        </div>
                    </div>
                    
                </section>
            </main>
            { back && handleRedirect() }
            { continuePurchase && handleContinuePurchase() }
        </CustomerLayout>
    );
};

export default PurchaseDetails;