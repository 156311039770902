import { API } from '../config'
import { isAuthenticated } from '../apiCall/auth'

export const getBuilding = () => {
    
    const token = isAuthenticated().token;
    
    return fetch(`${API}/building/get-list`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json', 
            Authorization: `Bearer ${token}`
        }
    })
    .then(response => {
        if(response.status !== 200) {

            if(response.status === 401) {
                localStorage.removeItem('fsb')
            } 

            return {
                status: response.status,
                message: response.status + ' ' + response.statusText,
                dataDictionary: null
            };
        }
        return response.json();
    })
    .catch(err => {
        return {
            status: 500,
            message: err.message,
            dataDictionary: null
        };
    });
}