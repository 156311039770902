import React, { useState } from 'react';
import { Link, Navigate } from "react-router-dom";
import Layout from '../components/Layouts/Layout';
import Spinner from "../components/Spinner";
import { forgotPassword } from '../apiCall/auth';

const ForgotPassword = () => {

    const [values, setValues] = useState({
        email: '',
        success: false, 
        error: false
    });

    const [errors, setErrors] = useState({
        email: '',
        messages: ''
    });

    const [showSpinner, setShowSpinner] = useState(false);

    const clickSubmit = event => {
        
        event.preventDefault();
        setShowSpinner(true);

        const { email } = values;

        var request = {
            EmailAddress: email
        };

        setErrors({...errors, email: '', message: '' });
        setValues({...values, error: true });

        forgotPassword(request).then(res => {
            if (res.isSuccess === true) {
                setShowSpinner(false);
                setValues({...values, error: false, success: true });
            } 
            else {
                setShowSpinner(false);
                if (res.status === 400) {
                    if(res.errors.EmailAddress) {
                        setErrors({...errors, email: res.errors.EmailAddress[0] });
                        setValues({...values, error: true, success: false });
                    }   
                }
                else {
                    setErrors({ ...errors, messages: res.message });
                }
            }
        })
    }
    
    const handleChange = name => event => {
        setValues({ ...values, error: false, [name]: event.target.value });
        setErrors({...errors, email: '', message: '' });
    };

    const redirectUser = () => {
        if (values.success) {
            return <Navigate to="/pages/forgot-password-confirmation" />;
        }
    };

    return (
        <Layout>
            <main>
                <div className="container">
                    <div className="row mt-160">
                        <div className="col-md text-center">
                            <h1 className="heading-tertiary u-margin-bottom-small">Forgot Password</h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md">
                            <form>
                                <div id="loginform">
                                    { showSpinner && <Spinner /> }
                                    <div className="row">
                                        <h3 id="warning-message">{ errors.messages }</h3>
                                    </div>
                                    <div className="row mb-40">
                                        <label>Email</label>
                                        <input type="email" className="form-control" placeholder="Enter your email" onChange={handleChange('email')} value={ values.email } />
                                        <div className="err-msg" style={{ display: errors.email ? '' : 'none' }}>
                                            <span>{errors.email}</span>
                                        </div>
                                    </div>
                                    <div id="button" className="row mb-2">
                                        <button onClick={clickSubmit}>
                                            Reset Password
                                        </button>
                                    </div>
                                    <div className="row mb-20">
                                        <label className="right" style={{width:"100%"}}>
                                            <Link to="/pages/login" style={{marginRight:"50px"}}>Back to Login</Link>
                                        </label>
                                    </div>
                                    <div className="row">&nbsp;</div>
                                </div>
                            </form>
                        </div>
                        
                    </div>
                </div>
            </main>
            { redirectUser() }
        </Layout>
    );
};

export default ForgotPassword;