import React, { Fragment } from 'react';

const OrderPaymentOptionReservation = (props) => {
    return(
        (!props.paymentOption.isInstallment && props.paymentOption.paymentOptionId == 5 && props.product.paymentOptionId == 5) &&
            <Fragment>
                <div className="description">
                    <table className="font-size-12 borderless">
                        <tbody>
                            <tr>
                                <td><b>Reservation Only</b></td>
                                <td>&nbsp;</td>
                            </tr>
                        </tbody>
                        
                    </table>
                </div>
            </Fragment>
    )
}

export default OrderPaymentOptionReservation;