import React from 'react';

const Spinner = (props) => {
    return (
        <div id="modal-root">
            <div className="spinner-box">
                <div className="loading-icon">
                </div>
            </div>
        </div>
    );
};

export default Spinner;