import React from 'react';
import Layout from '../components/Layouts/Layout'

const products = () => {

    const vault1 = 'https://res.cloudinary.com/fsb-land-holding/image/upload/v1619864570/project01/products/vault_standard_pazagn.png'
    const vault2 = 'https://res.cloudinary.com/fsb-land-holding/image/upload/v1619865011/project01/products/vault_suit_lqs6fe.png'
    const vault3 = 'https://res.cloudinary.com/fsb-land-holding/image/upload/v1620030401/project01/logo/TheOssuary_Logov2_nx5m0q.jpg'
    const vault4 = 'https://res.cloudinary.com/fsb-land-holding/image/upload/v1620030401/project01/logo/TheOssuary_Logov2_nx5m0q.jpg'
    const vault5 = 'https://res.cloudinary.com/fsb-land-holding/image/upload/v1620030401/project01/logo/TheOssuary_Logov2_nx5m0q.jpg'
    const vault6 = 'https://res.cloudinary.com/fsb-land-holding/image/upload/v1620030401/project01/logo/TheOssuary_Logov2_nx5m0q.jpg'

    return (
        <Layout>
            <main>
                <div className="container">
                    <div className="row mt-160 mb-40">
                        <div className="col-md text-center">
                            <h1 className="heading-tertiary u-margin-bottom-small">Vaults and Products</h1>
                        </div>
                    </div>
                    <div className="row mb-40">
                        <div className="col-md">
                            <div className="product">
                                <a href="#popup1">
                                    <img className="product__img" src={vault1} alt="Standard Vault" />
                                    <div className="product__caption">
                                        <h1>Standard<br />Vaults</h1>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div className="col-md">
                            <div className="product">
                                <a href="#popup2">
                                    <img className="product__img" src={vault2} alt="Vault Suites" />
                                    <div className="product__caption">
                                        <h1>Vault<br />Suites</h1>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div className="col-md">
                            <div className="product">
                                <a href="#popup5">
                                    <img className="product__img" src={vault5} alt="Urns" />
                                    <div className="product__caption">
                                        <h1>Urns<br />Coming Soon</h1>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-40">
                        <div className="col-md-4">
                            <div className="product" >
                                <a href="#popup6">
                                    <img className="product__img" src={vault6} alt="Pet Vaults" />
                                    <div className="product__caption">
                                        <h1>Pet Vaults<br />Coming Soon</h1>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <div className="popup" id="popup1"><div className="popup__content"><a href="#section" className="popup__close-light">&times;</a><img className="popup__img" src={vault1} alt="" /></div></div>
            <div className="popup" id="popup2"><div className="popup__content"><a href="#section" className="popup__close">&times;</a><img className="popup__img" src={vault2} alt="" /></div></div>
            <div className="popup" id="popup5">
                <div className="popup__content">
                    <div className="popup__left">
                        <img className="popup__img" src={vault3} alt="" />
                    </div>
                    <div className="popup__right">
                        <a href="#section" className="popup__close">&times;</a>
                        <h2 className="heading-secondary u-margin-bottom-small">Urns</h2>
                        <p className="font-size-24">Coming Soon</p>
                    </div>
                </div>
            </div>
            <div className="popup" id="popup6">
                <div className="popup__content">
                    <div className="popup__left">
                        <img className="popup__img" src={vault3} alt="" />
                    </div>
                    <div className="popup__right">
                        <a href="#section" className="popup__close">&times;</a>
                        <h2 className="heading-secondary u-margin-bottom-small">Pet Vaults</h2>
                        <p className="font-size-24">Coming Soon</p>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default products;