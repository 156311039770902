import React, { useEffect } from 'react';
import { Link } from "react-router-dom";
import Layout from '../components/Layouts/Layout';

const SignUpConfirmation = () => {
    
    useEffect(() => {
        goTop();
    }, []);

    const goTop = () => {
        window.scrollTo(0, 0);
    };

    return (
        <Layout>
            <main>
                <div className="container center">
                    <div className="row mt-160 mb-20">
                        <div className="col-md">
                            <div className="signup-confirmation vh-70">
                                <div className="wrapper-1">
                                    <div className="wrapper-2">
                                        <h1>Successful!</h1>
                                        <p>Thank you for signing up!  </p>
                                        <p>You should receive a confirmation email along with your credentials. </p>
                                        <p>Should there be no email received, please contact  info@fsblandholding.com. </p>
                                        <br /><br />
                                        <Link className="go-home" to="/pages/login">Click to login</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </Layout>
    );
};



export default SignUpConfirmation;