
const UnderConstruction = ({title}) => {
    return (
        <div className="container">
            <div className="row mt-160  mb-40">
                <div className="col-md text-center">
                    <h1 className="heading-tertiary u-margin-bottom-small">{title}</h1>
                </div>
            </div>
            <div className="row mb-40">
                <div className="col-md text-center">
                    <img src="https://res.cloudinary.com/fsb-land-holding/image/upload/v1619865914/project01/underconstruction_qlh30c.png" alt="" />
                </div>
            </div>
            <div className="row mb-40">
                <div className="col-md text-center">
                    <h1 className="heading-tertiary u-margin-bottom-small">Under Construction</h1>
                </div>
            </div>
        </div>
    );
}

export default UnderConstruction;